import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column } from "@components/Table";
import { Order, useCreate_One_Stripe_TransfertMutation } from "@graphql/";
import { Cancel } from "@mui/icons-material";
import { useApplicationContext } from "@src/context";
import moment from "moment";
import React, { Dispatch, SetStateAction } from "react";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ORDER_STATUSES } from "@utils/constants";

export const useSuperadminProps = ({
  setOrder,
  setShowTransfertHistory,
  entity,
}: {
  setOrder: Dispatch<SetStateAction<Order | undefined>>;
  setShowTransfertHistory: Dispatch<SetStateAction<boolean>>;
  entity?: { code: string } | null;
}) => {
  const { t } = useTranslation();
  const [createOneStripeTransfert] = useCreate_One_Stripe_TransfertMutation();
  const { notify } = useApplicationContext();
  const client = useApolloClient();
  const superadminActions = (row: Order) => {
    const handleSelect = (selected: string) => {
      setOrder(row);
      switch (selected) {
        case "PAY_PHARMACY":
          createOneStripeTransfert({
            variables: {
              input: {
                stripeTransfert: {
                  idOrder: row.id,
                  idUser: row.idVendor!,
                },
              },
            },
          })
            .then((result) => {
              client.refetchQueries({
                include: ["ORDERS"],
              });
              notify({
                message: t("stripeTransfert.form.success.create"),
                type: "success",
              });
            })
            .catch((err) => {
              notify({
                message: t("stripeTransfert.form.error.create"),
                type: "error",
              });
            });
          break;
        case "PAY_LIVREUR":
          createOneStripeTransfert({
            variables: {
              input: {
                stripeTransfert: {
                  idOrder: row.id,
                  idUser: row.idDeliveryMan!,
                },
              },
            },
          })
            .then((result) => {
              client.refetchQueries({
                include: ["ORDERS"],
              });
              notify({
                message: t("stripeTransfert.form.success.create"),
                type: "success",
              });
            })
            .catch((err) => {
              notify({
                message: t("stripeTransfert.form.error.create"),
                type: "error",
              });
            });
          break;
        case "TRANSFERT_HISTORY":
          setShowTransfertHistory(true);
          break;
      }
    };
    const menus = [
      {
        label: t("order.list.action.payDeliveryMan"),
        value: "PAY_LIVREUR",
        disabled: row.livreurPaid || row.status !== ORDER_STATUSES.DELIVERED,
      },
      {
        label: t("order.list.action.payPharmacy"),
        value: "PAY_PHARMACY",
        disabled: row.pharmacyPaid || row.status !== ORDER_STATUSES.DELIVERED,
      },
      {
        label: t("order.list.action.showTransfertHistory"),
        value: "TRANSFERT_HISTORY",
        disabled: !row.stripeTransferts?.length,
      },
    ]
    return <MoreHorizMenu items={menus} onSelect={handleSelect} />;
  };

  const superadminColumns: Column[] = !entity
    ? [
        {
          label: t("order.list.label.totalPrice"),
          name: "",
          renderer: (row: Order) => {
            return `${row.totalPrice.toFixed(2)}€`;
          },
        },
        {
          label: t("order.list.label.deliveryDate"),
          name: "",
          renderer: (row: Order) => {
            return row.deliveredAt
              ? moment(row.deliveredAt).format("DD/MM/YYYY à hh:mm")
              : "-";
          },
        },
        {
          label: t("stripePayout.deliveryMan"),
          name: "",
          centered: true,
          renderer: (row: Order) => {
            const stripePayout = row.stripePayouts?.find(
              (stripePayout) => stripePayout.idUser === row.idDeliveryMan
            );
            const updatedAt = moment(stripePayout?.updatedAt).format(
              "DD/MM/YYYY hh:mm"
            );
            const totalPrice = `${stripePayout?.data.amount / 100}€`;
            switch (stripePayout?.data.status) {
              case "failed":
                return `${stripePayout?.data.failure_message} (${updatedAt})`;
              case "pending":
                return `${t("commons.stripe.statuses.pending")} ${totalPrice} (${updatedAt})`;
              case "in_transit":
                return `${t("commons.stripe.statuses.inTransit")} ${totalPrice} (${updatedAt})`;
              case "paid":
                return `${t("commons.stripe.statuses.paid")} ${totalPrice} (${updatedAt})`;
              default:
                return <Cancel />;
            }
          },
        },
        {
          label: t("stripeTransfert.deliveryMan"),
          name: "",
          centered: true,
          renderer: (row: Order) => {
            const stripeTransfert = row.stripeTransferts?.find(
              (stripeTransfert) => stripeTransfert.idUser === row.idDeliveryMan
            );
            const updatedAt = moment(stripeTransfert?.updatedAt).format(
              "DD/MM/YYYY hh:mm"
            );
            const totalPrice = `${stripeTransfert?.data.amount / 100}€`;
            return stripeTransfert ? (
              `${t("commons.stripe.transfered")} ${totalPrice} (${updatedAt})`
            ) : (
              <Cancel />
            );
          },
        },
        {
          label: t("stripePayout.pharmacy"),
          name: "",
          centered: true,
          renderer: (row: Order) => {
            const stripePayout = row.stripePayouts?.find(
              (stripePayout) => stripePayout.idUser === row.idVendor
            );
            const updatedAt = moment(stripePayout?.updatedAt).format(
              "DD/MM/YYYY hh:mm"
            );
            const totalPrice = `${stripePayout?.data.amount / 100}€`;
            switch (stripePayout?.data.status) {
              case "failed":
                return `${stripePayout?.data.failure_message} (${updatedAt})`;
              case "pending":
                return `${t("commons.stripe.statuses.pending")} ${totalPrice} (${updatedAt})`;
              case "in_transit":
                return `${t("commons.stripe.statuses.inTransit")} ${totalPrice} (${updatedAt})`;
              case "paid":
                return `${t("commons.stripe.statuses.paid")} ${totalPrice} (${updatedAt})`;
              default:
                return <Cancel />;
            }
          },
        },
        {
          label: t("stripeTransfert.pharmacy"),
          name: "",
          centered: true,
          renderer: (row: Order) => {
            const stripeTransfert = row.stripeTransferts?.find(
              (stripeTransfert) => stripeTransfert.idUser === row.idVendor
            );
            const updatedAt = moment(stripeTransfert?.updatedAt).format(
              "DD/MM/YYYY hh:mm"
            );
            const totalPrice = `${stripeTransfert?.data.amount / 100}€`;
            return stripeTransfert ? (
              `${t("commons.stripe.transfered")} ${totalPrice} (${updatedAt})`
            ) : (
              <Cancel />
            );
          },
        },
      ]
    : [];
  return { superadminColumns, superadminActions };
};
