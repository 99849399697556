import React, { FC } from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFileStatuses } from "@src/hooks";

interface StatusChipProps {
  status: string;
}

export const StatusChip: FC<StatusChipProps> = ({ status }) => {
  const { t } = useTranslation();
  const { getFileByStatus } = useFileStatuses();
  return (
    <Chip
      label={getFileByStatus(status).label}
      sx={{
        color: "#FFFFFF",
        background: getFileByStatus(status).color,
      }}
    />
  );
};
