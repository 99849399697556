import  { FC } from "react";
import { Modal } from "@components/Modal";
import React from "react";
import { FormWrapper } from "@components/Form/Form";
import { InputProps } from "@src/types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStyles } from "./styles";
import { Box } from "@mui/material";
import { useState } from "react";
import { useOrdersQuery } from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useCreate_One_FeedbackMutation } from "@graphql/";

 
interface FeedbackFormProps  {
    open: boolean,
    setOpen: (value: React.SetStateAction<boolean>) => void;
    title: string
}

const FeedbackForm: FC<FeedbackFormProps> = ({open , setOpen , title}) => {
    const {connectedUser} = useApplicationContext()
    const { notify } = useApplicationContext();

    const [createFeedback , creatingFeedback] = useCreate_One_FeedbackMutation({
        onCompleted: () => {
            notify({
              message: t("feedback.success"),
              type: "success",
            });
          },
          onError: () => {
            notify({
              message:
                "Une erreur s'est produite lors de l'envoye du commentaire",
              type: "error",
            });
          },
    })

    const [category , setCategory] = useState("OTHERS")
    const ordersQuery = useOrdersQuery({variables:{filter:{idCustomer:{eq: connectedUser?.id}}}})
    const orders = ordersQuery.data?.orders?.nodes || []
    const orderRefOptions = orders.map((order) => {
        return {
            label: order.ref,
            value: order.ref
        }
    })
    const handleclose = () => {
        setOpen(false);
      };

      const classes = useStyles()
      const {t} = useTranslation()
      const schema = yup
                        .object({
                            category: yup.string().required("Categorie est requis"),
                            orderRef: yup.string().when("category", {
                                is: (category: string) => category !== "OTHERS",
                                then: yup.string().required(t("feedback.errors.requiredOrderRef")),
                                otherwise: yup.string().nullable(),
                              }),
                            comment: yup.string().optional(),
                            score: yup.number().required()
                        })
                        .required();


    const initialCreateInput = {
        orderRef: undefined,
        category: "OTHERS",
        score: 5,
        comment: ""

    };

    const categories = t("feedback.category", {returnObjects: true}) || {}

    const categoryOptions = Object.keys(categories).map((key) => {
        return {
            label: categories[key as keyof typeof categories],
            value: key
        }
    })

    const notes = t("feedback.score" , {returnObjects: true}) || {}

    const noteOptions = Object.keys(notes).map((key) => {
        return {
            label: `${key}-${notes[key as keyof typeof notes]}`,
            value: key
        }
    })

    const inputs: InputProps[] = [
        {
            type: "select",
            label: t("commons.category"),
            name: "category",
            options: categoryOptions,
            optionLabelKey: "label",
            optionValueKey: "value"
        },
        {
            type: "select",
            label: t("commons.orderRef"),
            name: "orderRef",
            hidden: category === "OTHERS",
            options: orderRefOptions
        },
        {
            type: "select",
            label: t("feedback.note"),
            name: "score",
            options: noteOptions,
            optionLabelKey: "label",
            optionValueKey: "value"
        },
        {
            type: "textarea",
            placeholder: t("feedback.yourComments"),
            name: "comment",
        }
    ]

    const handleChange = (state: typeof initialCreateInput) => {
        setCategory(state.category)
    }


  const handleCreateOneFeedback = async (state: typeof initialCreateInput) => {
    
      await createFeedback({
        variables: {
          input: {feedback:{category:state.category , score:state.score , comment:state.comment , orderRef:state.orderRef}},
        },
      });
      handleclose()
 
  };

  const loading = creatingFeedback.loading


    const handleSubmit = (state: typeof initialCreateInput) => {
        if(state.category === "OTHERS"){
            state.orderRef = undefined
        }
        console.log(state)
        handleCreateOneFeedback(state)
    }

      return(
        <Box > 
          <Modal 
              open={open}
              onClose={handleclose}
              title={title}
              children={ <Box className={classes.root}> 
                <FormWrapper 
                    initialCreateInput={initialCreateInput}
                    inputs={inputs}
                    onSubmit={handleSubmit}
                    loading={loading}
                    schema={schema}
                    submitBtnLabel={t("commons.send")}
                    onChange={handleChange}
          />
          </Box>}
        />
        </Box>)

        
       
   
}

export default FeedbackForm


