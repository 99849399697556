import { MoreHoriz } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { FC } from "react";

interface MoreHorizMenuProps {
  onSelect: (selected: string) => void;
  items: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
  disabled?: boolean;
}

export const MoreHorizMenu: FC<MoreHorizMenuProps> = ({
  onSelect,
  items,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        id="more-horiz-icon-button"
        aria-controls={open ? "more-horiz-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={disabled}
        color="primary"
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="more-horiz-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-horiz-icon-button",
        }}
      >
        {items.filter((item) => !item.disabled).map((item) => (
          <MenuItem
            key={`more-horiz-menu-item-${item.value}`}
            onClick={() => {
              onSelect(item.value);
              handleClose();
            }}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
