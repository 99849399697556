import { ForgotPasswordForm } from "@components/ForgotPasswordForm";
import { FormWrapper } from "@components/Form/Form";
import { useSigninLazyQuery } from "@graphql/";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation , } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InputProps } from "src/types";
import * as yup from "yup";
import { useApplicationContext } from "../../context";
import { useStyles } from "./styles";
import { aesEncrypt } from "@utils/encrypt";
import Logo from "@src/assets/img/logo.png"

interface State {
  email: string;
  password: string;
}
const SignIn: FC = () => {
  const { t } = useTranslation();
  const { notify } = useApplicationContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const [showForgotPasswordForm, setShowForgotPasswordForm] =
    useState<boolean>(false);
  const initialCreateInput = {
    email: "",
    password: "",
  };

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("signin.errors.invalidEmail"))
        .required(t("signin.errors.invalidEmail")),
      password: yup.string().required(t("signin.errors.passwordRequired")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      "data-cy": "email",
      name: "email",
      label: t("commons.email"),
      type: "text",
      placeholder: t("commons.email"),
    },
    {
      "data-cy": "password",
      name: "password",
      label: t("signin.password"),
      type: "password",
      placeholder: t("signin.password"),
    },
  ];

  const [signin, { loading }] = useSigninLazyQuery({
    fetchPolicy: "network-only",
  });
  const onSubmit = async (state: State) => {
    signin({
      variables: { email: state.email, password: aesEncrypt(state.password) },
      onCompleted: (data) => {
        const idUser = data?.signin;
        if (idUser) {
          navigate(`/authentification-2fa?idUser=${idUser}`);
        }
      },
      onError: (error) => {
        notify({
          message: error.message,
          type: "error",
        });
      },
    });
  };

  return (
    <Box className={classes.root}>
      <Card
        className={classes.card}
        sx={{ maxWidth: { lg: "25%", xs: "90%" } }}
      >
        <img className={classes.logo} src={Logo} />
        <CardContent className={classes.cardContent}>
          <Typography variant="h3" mb={3}>
            {t("signin.title")}
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            {t("signin.subTitle")}
          </Typography>
          <FormWrapper
            initialCreateInput={initialCreateInput}
            onSubmit={onSubmit}
            schema={schema}
            loading={loading}
            submitBtnLabel={t("signin.login")}
            inputs={inputs}
            submitBtnDataCy="signinBtn"
          />
          <Typography
            color="primary"
            sx={{
              cursor: "pointer",
              mt: 3,
              mb: 1,
              textDecoration: "underline",
            }}
            variant="subtitle2"
            onClick={() => setShowForgotPasswordForm(true)}
            data-cy="forgotPasswordLink"
          >
            {t("signin.forgotPassword")}
          </Typography>
          <Link
            href={process.env.CLIENT_URL}
            textAlign="center"
            variant="subtitle2"
          >
            {t("signin.returnToHomePage")}
          </Link>
        </CardContent>
      </Card>
      <ForgotPasswordForm
        open={showForgotPasswordForm}
        onClose={() => setShowForgotPasswordForm(false)}
      />
    </Box>
  );
};

export { SignIn };
