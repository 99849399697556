import { CreateOrderState } from "@components/CreateOrderStepper";
import {
  Order,
  Ordonnance as OrdonnanceType,
  useOrdonnancesLazyQuery,
} from "@graphql/";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useStyles } from "./styles";
import { FileUploader } from "react-drag-drop-files";
import { useFilesMutations } from "@src/hooks";
import { useApplicationContext } from "@src/context";
import { getFileType } from "@utils/getFileType";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";
import { FILE_NAMES } from "@utils/constants";

interface OrdonnanceProps {
  setState: Dispatch<SetStateAction<CreateOrderState | undefined>>;
  state?: CreateOrderState;
  disableNextStepBtn?: boolean;
  onPreviousStep: () => void;
  order?: Order;
  onNextStep: () => void;
}

export const Ordonnance: FC<OrdonnanceProps> = ({
  setState,
  state,
  onPreviousStep,
  disableNextStepBtn,
  order,
  onNextStep,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const [fetchOrdonnance, fetchingOrdonnance] = useOrdonnancesLazyQuery();
  const { uploadFiles, isUploading } = useFilesMutations();
  const handleFileUploaderChange = (file: File) => {
    setState((prev) => ({ ...prev, ordonnance: undefined }));
    setFile(file);
  };
  const { connectedUser } = useApplicationContext();
  const idCustomer = connectedUser?.id;

  const fileTypes = ["JPEG", "PNG", "PDF", "JPG"];

  const uploadOrdonnance = () => {
    if (!file) {
      throw "file is not defined";
    }
    uploadFiles([
      {
        file: file,
        name: FILE_NAMES.ORDONNANCE,
        filename: file?.name || "",
        type: "ORDER",
        idType: order?.id,
        idUser: idCustomer,
      },
    ]).then(async (data) => {
      const ordonnanceResult = await fetchOrdonnance({
        variables: {
          filter: { file: { id: { eq: data[0].id } } },
        },
      });
      setState((prev) => ({
        ...prev,
        ordonnance: ordonnanceResult.data?.ordonnances
          .nodes[0] as OrdonnanceType,
      }));
    });
  };
  return (
    <>
      <Box>
        <Typography variant="h5" mb={1}>
          Ordonnance
        </Typography>
        {state?.ordonnance ? (
          <Box>
            <Paper
              variant="outlined"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                maxWidth: "100%",
                mb: 1,
              }}
            >
              {getFileType(state.ordonnance.file.mimeType) === "pdf" && (
                <PdfViewer
                  url={decryptFilePublicUrl(state.ordonnance.file.url)}
                  width={600}
                />
              )}
              {getFileType(state.ordonnance.file.mimeType) === "image" && (
                <img
                  className={classes.fileContainer}
                  src={decryptFilePublicUrl(state.ordonnance.file.url)}
                  alt={`carte-vitale-${state.ordonnance.file.id}`}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              )}
            </Paper>
          </Box>
        ) : (
          <Typography mb={2}>Télécharger une ordonnance valide:</Typography>
        )}
        <FileUploader
          multiple={false}
          label="Télécharger ou déposer votre ordonnance ici"
          handleChange={handleFileUploaderChange}
          types={fileTypes}
        />
      </Box>

      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={onPreviousStep}
          className={classes.createBtn}
          sx={{ marginRight: 2 }}
        >
          Précédent
        </Button>
        {state?.ordonnance ? (
          <Button
            variant="contained"
            onClick={onNextStep}
            className={classes.createBtn}
            disabled={disableNextStepBtn}
          >
            {order ? "Modifier ma commande" : "Créer ma commande"}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={uploadOrdonnance}
            className={classes.createBtn}
            disabled={isUploading}
          >
            Télécharger ordonnance
          </Button>
        )}
      </Box>
    </>
  );
};
