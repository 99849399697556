import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileInfoFragmentDoc } from '../../File/__generated__/fragment';
export type SupportTicketInfoFragment = { __typename?: 'SupportTicket', id: string, idOrder?: string | null, idCustomer: string, idServiceCustomer?: string | null, unreadSupportTicketMessageCount: number, category: string, priority: string, ref: string, subject: string, description?: string | null, status: string, suggestion?: any | null, createdAt: any, updatedAt?: any | null, order?: { __typename?: 'Order', id: string, ref: string } | null, customer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, serviceCustomer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, files?: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> | null };

export const SupportTicketInfoFragmentDoc = gql`
    fragment SupportTicketInfo on SupportTicket {
  id
  idOrder
  idCustomer
  idServiceCustomer
  unreadSupportTicketMessageCount
  category
  priority
  order {
    id
    ref
  }
  customer {
    id
    firstName
    lastName
  }
  serviceCustomer {
    id
    firstName
    lastName
  }
  files {
    ...FileInfo
  }
  ref
  subject
  description
  status
  suggestion
  createdAt
  updatedAt
}
    ${FileInfoFragmentDoc}`;