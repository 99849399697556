import { Edit } from "@mui/icons-material";
import { Chip, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { FC, useState } from "react";

interface ChipSelectProps {
  menuItems: { value: string | boolean; label: string; color: string }[];
  value: string | boolean;
  onChange: (value: string | boolean) => void;
  disabled?: boolean;
  width?: number;
}

export const ChipSelect: FC<ChipSelectProps> = ({
  menuItems,
  value,
  onChange,
  disabled,
  width,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const active = menuItems.find((option) => option.value === value);
  const handleChange = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value);
    setIsEditing(false);
  };
  return (
    <>
      {isEditing ? (
        <Select
          onChange={handleChange}
          value={value}
          variant="outlined"
          onClose={() => setIsEditing(false)}
          open={isEditing}
        >
          {menuItems.map((option) => (
            <MenuItem
              key={`select-chip-${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Chip
          label={active?.label}
          sx={{ color: "#FFFFFF", background: active?.color, width }}
          deleteIcon={
            disabled ? undefined : <Edit sx={{ color: "#FFFFFF !important" }} />
          }
          onDelete={disabled ? undefined : () => setIsEditing(true)}
        />
      )}
    </>
  );
};
