import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import { File } from "@graphql/";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { useStyles } from "./styles";
import { getFileType } from "@utils/getFileType";
import { useTranslation } from "react-i18next";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";
import { useFileStatuses } from "@src/hooks";

interface FileViewerProps {
  files: File[];
  onStatusChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const FileViewer: FC<FileViewerProps> = ({
  files,
  onStatusChange,
  loading,
  disabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getFileByStatus, fileStatuses } = useFileStatuses();
  const fileNames = {
    ASSURANCE: t("file.names.ASSURANCE"),
    CARTE_GRISE: t("file.names.CARTE_GRISE"),
    VISITE_TECHNIQUE: t("file.names.VISITE_TECHNIQUE"),
    MUTUELLE: t("file.names.MUTUELLE"),
    CARTE_VITALE: t("file.names.CARTE_VITALE"),
    ORDONNANCE: t("file.names.ORDONNANCE"),
    RIB: t("file.names.RIB"),
    KBIS: t("file.names.KBIS"),
    CONTRAT: t("file.names.CONTRAT"),
  };

  return (
    <Box sx={{ justifyContent: "center", alignItems: "center" }}>
      {files.map((file) => (
        <Box key={`file-${file.id}`} mb={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography mr={2}>
              <b>{fileNames[file.name as keyof typeof fileNames]}:</b>{" "}
              {file.filename}
            </Typography>

            <Select
              size="small"
              name={file.id}
              value={file.status as string}
              onChange={onStatusChange}
              startAdornment={getFileByStatus(file.status).icon}
              sx={{
                color: getFileByStatus(file.status).color,
              }}
              disabled={disabled}
            >
              {fileStatuses.map((fileStatus) => (
                <MenuItem key={`select-${fileStatus.status}`} value={fileStatus.value}>
                  {fileStatus.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {getFileType(file.mimeType) === "pdf" && (
              <PdfViewer url={decryptFilePublicUrl(file.url)} width={600} />
            )}
            {getFileType(file.mimeType) === "image" && (
              <img
                className={classes.fileContainer}
                src={decryptFilePublicUrl(file.url)}
                alt={`file-${file.id}`}
              />
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
