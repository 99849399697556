import { SignIn } from "@pages/SignIn";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  RouteProps,
} from "react-router-dom";
import "./App.css";
import ApplicationContext, {
  ApplicationContextOptions,
} from "./context/ApplicationContext";
import { useDisplayNotification } from "./lib";
import React, { Suspense, useEffect, useState } from "react";
import { SignUp } from "@pages/SignUp";
import { AccountVerification } from "@pages/AccountVerification";
import { Home } from "@pages/Home";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dashboard as DashboardLayout } from "./layouts/Dashboard";
import { MyOrders } from "@pages/Dashboard/MyOrders";
import { Users } from "@pages/Superadmin/Users";
import { Tarification } from "@pages/Settings/Tarification";
import { CheckoutSession } from "@pages/CheckoutSession/CheckoutSession";
import { useJsApiLoader } from "@react-google-maps/api";
import { Users as DashboardUsers } from "@pages/Dashboard/Users";
import { Dashboard as PharmacyDashboard } from "@pages/Dashboard/Dashboard";
import { LivreurDashboard } from "@pages/Dashboard/LivreurDashboard";
import { AccountLimited } from "@pages/AccountLimited/AccountLimited";
import {
  SupportTicket,
  User,
  UserInfoFragment,
  Verify_Bearer_TokenDocument,
  useVerify_Bearer_TokenQuery,
} from "./graphql";
import { MyTickets } from "@pages/ServiceCustomer/MyTickets";
import { Customers } from "@pages/ServiceCustomer/Customers";
import { UnassignedOrders } from "@pages/ServiceCustomer/UnassignedOrders";
import { Dashboard as SuperadminDashboard } from "@pages/Superadmin/Dashboard";
import ThemeProvider from "./Theme/ThemeProvider";
import { useApolloClient } from "@apollo/client";
import { StripeAccounts } from "@pages/Superadmin/StripeAccounts";
import { postSigninNavigation } from "@utils/postSigninNavigation";
import { NotFound } from "@pages/NotFound/NotFound";
import { Authentification2fa } from "@pages/Authentification2fa";
import { Settings } from "@pages/Settings";
import { SplashScreen } from "@components/SplashScreen";
import { ServiceCustomerDashboard } from "@pages/Dashboard/ServiceCustomerDashboard";
import { Faq } from "@pages/Faq";
import { Urgents } from "@pages/ServiceCustomer/Urgents";

function App() {
  const notify = useDisplayNotification();
  const client = useApolloClient();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const connectedUserQuery = useVerify_Bearer_TokenQuery({
    skip: localStorage.getItem("token") == null,
    onCompleted: (data) => {
      if (data.verifyBearerToken.id && ["/signin", "/"].includes(pathname)) {
        const path = postSigninNavigation(data.verifyBearerToken as User);
        navigate(path || "/signin");
      }
    },
    onError: (error) => {
      localStorage.clear();
      navigate("/signin");
      window.location.reload();
    },
  });
  const connectedUser = connectedUserQuery.data?.verifyBearerToken;
  const [token, setToken] = useState<string | null>(null);
  const [supportTicket, setSupportTicket] = useState<SupportTicket>();
  const openMessagerie = supportTicket !== undefined

  const connectedUserType = () => {
    if (connectedUser?.role?.code === "SUPERADMIN") {
      return "SUPERADMIN";
    } else if (connectedUser?.role?.code === "SERVICE_CUSTOMER") {
      return "SERVICE_CUSTOMER";
    } else if (connectedUser?.idAdmin) {
      return "PREPARER";
    } else {
      return connectedUser?.entity?.code as
        | "INDIVIDUAL"
        | "PROFESSIONAL"
        | "PHARMACY"
        | "LIVREUR";
    }
  };

  const updateConnectedUser = (user?: UserInfoFragment) => {
    if (!user) {
      console.error("updateConnectedUser: user is not defined");
    }
    client.writeQuery({
      query: Verify_Bearer_TokenDocument,
      data: {
        verifyBearerToken: user,
      },
    });
  };

  const updateToken = (token: string) => {
    setToken(token);
  };

  const appContextValue: ApplicationContextOptions = {
    notify,
    connectedUser: connectedUser as User,
    refetchConnectedUser: connectedUserQuery.refetch,
    messagerie: { supportTicket, setSupportTicket, open: openMessagerie },
    isCustomer: ["INDIVIDUAL", "PROFESSIONAL"].includes(
      connectedUser?.entity?.code || ""
    ),
    connectedUserType: connectedUserType(),
    updateConnectedUser: updateConnectedUser,
    isLimitedAccount: connectedUser?.isLimitedAccount || false,
    updateToken: updateToken,
    token,
  };

  const { isLoaded } = useJsApiLoader({
    id: `google-map-delivdose`,
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY as string,
    libraries: ["places"],
  });

  useEffect(() => {
    if (!/(safari)/i.test(navigator.userAgent)) {
      navigator.permissions.query({ name: "geolocation" }).then(({ state }) => {
        switch (state) {
          case "granted":
            console.log("Geolocation Permission Granted");
            break;
          case "prompt":
            console.log("Geolocation Permission Prompt");
            navigator.geolocation.getCurrentPosition(() => {
              console.log("Geolocation Permission Granted");
            });
            break;
          default:
            console.log("Geolocation Permission Denided");
        }
      });
    } else {
      navigator.geolocation.getCurrentPosition(() => {
        console.log("Geolocation Permission Granted");
      });
    }
  }, []);

  return (
    <ApplicationContext.Provider value={appContextValue as any}>
      <ThemeProvider>
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/signin"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <SignIn />
                </Suspense>
              }
            />
            <Route
              path="/signup"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <SignUp />
                </Suspense>
              }
            />
            <Route
              path="/authentification-2fa"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Authentification2fa />
                </Suspense>
              }
            />
            <Route
              path="/stripe-checkout-session"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <CheckoutSession />
                </Suspense>
              }
            />
            <Route
              path="/account-verification"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <AccountVerification />
                </Suspense>
              }
            />
            <Route
              path="/faq"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Faq />
                </Suspense>
              }
            />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="my-orders" element={<MyOrders />} />
              <Route path="my-users" element={<DashboardUsers />} />
              <Route
                path="pharmacy-dashboard"
                element={<PharmacyDashboard />}
              />
              <Route path="livreur-dashboard" element={<LivreurDashboard />} />
              <Route path="service-customer-dashboard" element={<ServiceCustomerDashboard />} />
              <Route path="tarifications" element={<Tarification />} />
              <Route path="users" element={<Users />} />
              <Route path="orders" element={<MyOrders />} />
              <Route path="account-limited" element={<AccountLimited />} />
              <Route path="my-tickets" element={<MyTickets />} />
              <Route path="customers" element={<Customers />} />
              <Route path="unassigned-orders" element={<UnassignedOrders />} />
              <Route path="urgents" element={<Urgents />} />
              <Route
                path="superadmin-dashboard"
                element={<SuperadminDashboard />}
              />
              <Route path="stripe-accounts" element={<StripeAccounts />} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route
              path="*"
              element={
                <Suspense fallback={<SplashScreen />}>
                  <NotFound />
                </Suspense>
              }
            />
          </Routes>
        </LocalizationProvider>
      </ThemeProvider>
    </ApplicationContext.Provider>
  );
}

export default App;
