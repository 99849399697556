import React, { ChangeEventHandler, FC } from "react";
import { File as FileType } from "@graphql/";
import { Box, Button, Chip, Typography } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useFileStatuses } from "@src/hooks";

interface FileInputProps {
  label: string;
  name: string;
  onFileChange: ChangeEventHandler<HTMLInputElement>;
  file: File | FileType | undefined;
}

export const FileInput: FC<FileInputProps> = ({
  label,
  name,
  onFileChange,
  file,
}) => {
  const { getFileByStatus } = useFileStatuses();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 2,
        justifyContent: "space-between",
      }}
    >
      <Box display="flex">
        <Typography fontWeight="bold" mr={1}>
          {label}:
        </Typography>
        {file?.name}
        <input
          style={{ display: "none" }}
          id={name}
          name={name}
          onChange={onFileChange}
          type="file"
        />
      </Box>
      <Box>
        {file && (file as FileType).status && (
          <Chip
            label={getFileByStatus((file as FileType).status).label}
            sx={{
              color: "#FFFFFF",
              background: getFileByStatus((file as FileType).status).color,
            }}
          />
        )}

        <label htmlFor={name}>
          <Button component="span">
            <FileUpload />
          </Button>
        </label>
      </Box>
    </Box>
  );
};
