import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SupportTicketInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Support_TicketsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SupportTicketFilter>;
  sorting?: Types.InputMaybe<Array<Types.SupportTicketSort> | Types.SupportTicketSort>;
}>;


export type Support_TicketsQuery = { __typename?: 'Query', supportTickets: { __typename?: 'SupportTicketConnection', totalCount: number, nodes: Array<{ __typename?: 'SupportTicket', id: string, idOrder?: string | null, idCustomer: string, idServiceCustomer?: string | null, unreadSupportTicketMessageCount: number, category: string, priority: string, ref: string, subject: string, description?: string | null, status: string, suggestion?: any | null, createdAt: any, updatedAt?: any | null, order?: { __typename?: 'Order', id: string, ref: string } | null, customer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, serviceCustomer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, files?: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> | null }> } };

export type Support_Tickets_CountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Support_Tickets_CountsQuery = { __typename?: 'Query', supportTicketsCounts: any };


export const Support_TicketsDocument = gql`
    query SUPPORT_TICKETS($paging: OffsetPaging, $filter: SupportTicketFilter, $sorting: [SupportTicketSort!]) {
  supportTickets(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...SupportTicketInfo
    }
    totalCount
  }
}
    ${SupportTicketInfoFragmentDoc}`;

/**
 * __useSupport_TicketsQuery__
 *
 * To run a query within a React component, call `useSupport_TicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupport_TicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupport_TicketsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSupport_TicketsQuery(baseOptions?: Apollo.QueryHookOptions<Support_TicketsQuery, Support_TicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Support_TicketsQuery, Support_TicketsQueryVariables>(Support_TicketsDocument, options);
      }
export function useSupport_TicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Support_TicketsQuery, Support_TicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Support_TicketsQuery, Support_TicketsQueryVariables>(Support_TicketsDocument, options);
        }
export type Support_TicketsQueryHookResult = ReturnType<typeof useSupport_TicketsQuery>;
export type Support_TicketsLazyQueryHookResult = ReturnType<typeof useSupport_TicketsLazyQuery>;
export type Support_TicketsQueryResult = Apollo.QueryResult<Support_TicketsQuery, Support_TicketsQueryVariables>;
export const Support_Tickets_CountsDocument = gql`
    query SUPPORT_TICKETS_COUNTS {
  supportTicketsCounts
}
    `;

/**
 * __useSupport_Tickets_CountsQuery__
 *
 * To run a query within a React component, call `useSupport_Tickets_CountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupport_Tickets_CountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupport_Tickets_CountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupport_Tickets_CountsQuery(baseOptions?: Apollo.QueryHookOptions<Support_Tickets_CountsQuery, Support_Tickets_CountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Support_Tickets_CountsQuery, Support_Tickets_CountsQueryVariables>(Support_Tickets_CountsDocument, options);
      }
export function useSupport_Tickets_CountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Support_Tickets_CountsQuery, Support_Tickets_CountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Support_Tickets_CountsQuery, Support_Tickets_CountsQueryVariables>(Support_Tickets_CountsDocument, options);
        }
export type Support_Tickets_CountsQueryHookResult = ReturnType<typeof useSupport_Tickets_CountsQuery>;
export type Support_Tickets_CountsLazyQueryHookResult = ReturnType<typeof useSupport_Tickets_CountsLazyQuery>;
export type Support_Tickets_CountsQueryResult = Apollo.QueryResult<Support_Tickets_CountsQuery, Support_Tickets_CountsQueryVariables>;