import SwitchLanguage from "@components/SwitchLanguage/SwitchLanguage";
import { AppBar, Box, Toolbar, Typography, Divider, OutlinedInput } from "@mui/material";
import { getFaqs } from "@src/services";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import LogoGm from "@src/assets/img/logo-gm.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Search } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useApplicationContext } from "@src/context";
import { useNavigate } from "react-router-dom";


export const Faq: FC = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [faqs, setFaqs] = useState({});
  const [activeFaqs, setActiveFaqs] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredfaqs , setFilteredfaqs] = useState([])
  const { connectedUser , connectedUserType} = useApplicationContext(); 
  const navigate = useNavigate();

  useEffect(() => {
    getFaqs().then((response) => {
      setFaqs(response);
      setActiveFaqs(response.fr);
      setFilteredfaqs(response.fr)
    });
  }, []);

  useEffect(() => {
    const faq = faqs[i18n.language as keyof typeof faqs];
    if (faq) setActiveFaqs(faq);
  }, [i18n.language]);

 

  useEffect(() => {
    if(!searchTerm.length) {
      setFilteredfaqs(activeFaqs)
    }
    else {
      onSearch()
    }
  }, [searchTerm]);
  

  const searchInValue = (value: any):boolean => {

    const lowerCaseSearchText = searchTerm.toLowerCase();
    if (typeof value === 'string') {
        return value.toLowerCase().includes(lowerCaseSearchText);
    } else if (Array.isArray(value)) {
        return value.some(item => searchInValue(item));
    } else if (typeof value === 'object' && value !== null) {
        return Object.values(value).some(subValue => searchInValue(subValue));
    }
    return false;
    
  }

  const onSearch = () => {

    
   const result = Object.entries(activeFaqs).reduce((acc, [key, value]) => {
    if (searchInValue(value)) {
        acc.push(value);
    }
    return acc;
}, []);
   setFilteredfaqs(result)
}

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };
 
  const handleReturn = () => {
    if (connectedUser) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  

  return (
    <Box>
    <AppBar position="static" className={classes.appBar}>
    <Toolbar color="primary">
      <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
        <IconButton
          size="large"
          aria-label="show faq"
          onClick={handleReturn}
          sx={{ marginRight: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box component="img" src={LogoGm} height={64} alt="Logo" />
      </Box>
      <SwitchLanguage />
    </Toolbar>
  </AppBar>

 

    <Box display="flex" justifyContent="center" mt={3} mb={3}>
      <OutlinedInput
        onChange={handleSearchValueChange}
        endAdornment={<Search />}
        sx={{ 
          borderRadius: 8, 
          maxWidth: 400, 
        }}
        value={searchTerm}
      />
    </Box>
    {filteredfaqs.map((el: { question: string; comment?: string; responses: string[] }, i) => (
      <Box sx={{ maxWidth: 600, margin: "16px auto" }} key={`faq-question-${i}`}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i + 1}-content`}
            key={`panel${i + 1}-header`}
          >
            <Typography variant="h6">{`${i + 1} - ${el.question}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {el.comment && (
              <Typography variant="body2" color="textSecondary" sx={{ fontStyle: "italic", marginLeft: 2 }}>
                {el.comment}
              </Typography>
            )}
            <Divider sx={{ marginY: 1 }} />
            {el.responses.map((response, index) => (
              <Typography key={`faq-response-${i}-${index}`} variant="body2" sx={{ marginLeft: 2 }}>{`* ${response}`}</Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    ))}
  </Box>
  
  );
};
