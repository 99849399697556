import React, { FC, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

interface PdfViewerProps {
  url: string;
  width: number;
}

export const PdfViewer: FC<PdfViewerProps> = ({ url, width }) => {
  const [numPages, setNumPages] = useState<number>();

  const pdfOptions = useMemo(() => {
    return { cMapUrl: "/cmaps/" };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  return (
    <Document
      file={process.env.PRIVACY_POLICY_URL}
      options={pdfOptions}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
      ))}
    </Document>
  );
};
