import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Verify_Reduction_CodeMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type Verify_Reduction_CodeMutation = { __typename?: 'Mutation', verifyReductionCode: number };


export const Verify_Reduction_CodeDocument = gql`
    mutation VERIFY_REDUCTION_CODE($code: String!) {
  verifyReductionCode(code: $code)
}
    `;
export type Verify_Reduction_CodeMutationFn = Apollo.MutationFunction<Verify_Reduction_CodeMutation, Verify_Reduction_CodeMutationVariables>;

/**
 * __useVerify_Reduction_CodeMutation__
 *
 * To run a mutation, you first call `useVerify_Reduction_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerify_Reduction_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyReductionCodeMutation, { data, loading, error }] = useVerify_Reduction_CodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerify_Reduction_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Verify_Reduction_CodeMutation, Verify_Reduction_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Verify_Reduction_CodeMutation, Verify_Reduction_CodeMutationVariables>(Verify_Reduction_CodeDocument, options);
      }
export type Verify_Reduction_CodeMutationHookResult = ReturnType<typeof useVerify_Reduction_CodeMutation>;
export type Verify_Reduction_CodeMutationResult = Apollo.MutationResult<Verify_Reduction_CodeMutation>;
export type Verify_Reduction_CodeMutationOptions = Apollo.BaseMutationOptions<Verify_Reduction_CodeMutation, Verify_Reduction_CodeMutationVariables>;