import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: `${theme.palette.common.white} !important`,
      boxShadow: `0px 2px 4px -1px ${theme.palette.primary.main}, 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px ${theme.palette.primary.main} !important`,
    }
  })
);
