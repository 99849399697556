import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 600,
      [theme.breakpoints.down("sm")]: {
        minWidth: "unset",
      },
    },
    boxOption: {
      display: "flex",
      justifyContent: "space-between",
      padding: 8
    }
  })
);
