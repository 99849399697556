import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Notifications } from "@components/Notifications";
import { useApplicationContext } from "@src/context";
import { useNavigate } from "react-router-dom";
import { useUsersMutations } from "@src/hooks";
import LogoGm from "@src/assets/img/logo-gm.png";
import { useStyles } from "./styles";
import SwitchLanguage from "@components/SwitchLanguage/SwitchLanguage";

interface NavbarProps {
  onDrawerToggle: () => void;
  onUserFormToggle: () => void;
  onLogout: () => void;
}

export const NavBar: FC<NavbarProps> = ({
  onDrawerToggle,
  onUserFormToggle,
  onLogout,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const classes = useStyles();
  const { connectedUser, isCustomer, connectedUserType } =
    useApplicationContext();
  const { updateUser } = useUsersMutations({ skipRefetch: true });
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const entities = {
    INDIVIDUAL: t("layout.customerArea"),
    PROFESSIONAL: t("layout.professionalArea"),
    LIVREUR: t("layout.livreurArea"),
    PHARMACY: t("layout.pharmacyArea"),
    SERVICE_CUSTOMER: t("layout.serviceCustomerArea"),
    SUPERADMIN: t("layout.superAdminArea"),
    PREPARER: t("layout.preparerArea"),
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const goToMyTickets = () => {
    handleCloseMenu();
    navigate("/dashboard/my-tickets");
  };

  const handleDeliveryAvailabilityChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    updateUser({
      variables: {
        input: {
          id: connectedUser?.id,
          update: {
            deliveryAvailability: checked ? "AVAILABLE" : "UNAVAILABLE",
          },
        },
      },
    });
  };

  const customerItems = isCustomer
    ? [
        {
          onClick: goToMyTickets,
          label: t("sideNav.myTickets"),
        },
      ]
    : [];

  const handleOpenUserForm = () => {
    handleCloseMenu();
    onUserFormToggle();
  };

  const handleOpenFaq = () => {
    handleCloseMenu();
    navigate("/faq");
  };

  const menuItems = [
    {
      onClick: handleOpenUserForm,
      label: t("sideNav.myAccount"),
    },
    { onClick: handleOpenFaq, label: t("faq.label") },
    ...customerItems,
    {
      onClick: onLogout,
      label: t("sideNav.logOut"),
    },
  ];

  useEffect(() => {
    const INTERVAL = 1 * 60 * 1000;
    if (
      connectedUser?.entity?.code === "LIVREUR" &&
      connectedUser?.deliveryAvailability === "AVAILABLE"
    ) {
      const intervalId = setInterval(() => {
        console.log("sendingLastPosition");
        navigator.geolocation.getCurrentPosition((position) => {
          /*
          Tour Eiffel => Elisée = 4.5 km (48.8749504, 2.3194796)
          Tour Eiffel => E.leclerc Nanterre = 10.0 km (48.8831329, 2.2023615)
          */
          updateUser({
            variables: {
              input: {
                id: connectedUser?.id,
                update: {
                  lastPosition: {
                    latitude: 48.8749504, // position.coords.latitude,
                    longitude: 2.3194796, // position.coords.longitude,
                  },
                },
              },
            },
          });
        });
      }, INTERVAL);
      setIntervalId(intervalId);
    } else {
      clearInterval(intervalId);
    }
  }, [connectedUser?.deliveryAvailability, connectedUser?.entity?.code]);
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        classes={{ root: classes.appBar }}
      >
        <Toolbar color="primary">
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={onDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <img src={LogoGm} height={64} />
            <Typography color="primary" marginLeft={2} fontWeight="bold">
              {entities[connectedUserType]}
            </Typography>
          </Box>
          <SwitchLanguage />
          <div>
            {connectedUser?.entity?.code === "LIVREUR" && (
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      connectedUser?.deliveryAvailability === "AVAILABLE"
                    }
                    onChange={handleDeliveryAvailabilityChange}
                    sx={{
                      "& .MuiButtonBase-root": {
                        color:
                          connectedUser?.deliveryAvailability === "AVAILABLE"
                            ? "#1246B8 !important"
                            : "#DCDCDC",
                      },
                    }}
                    color="warning"
                  />
                }
                label={t("sideNav.availability")}
                classes={{ label: classes.availabilityLabel }}
                sx={{ display: { xs: "none", sm: "unset" } }}
              />
            )}

            <Notifications />
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{ display: { xs: "none", lg: "initial" } }}
            >
              <Avatar
                classes={{ root: classes.avatar }}
              >{`${connectedUser?.firstName[0]}${connectedUser?.lastName[0]}`}</Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuAnchorEl)}
              onClose={handleCloseMenu}
            >
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  key={`navbar-menu-item-${index}`}
                  onClick={menuItem.onClick}
                >
                  {menuItem.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
