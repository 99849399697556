import { ChipSelect } from "@components/ChipSelect";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { SupportTicketDetails } from "@components/SupportTicketDetails";
import { SupportTicketForm } from "@components/SupportTicketForm";
import { Column, Table } from "@components/Table";
import UserSelect from "@components/UserSelect/UserSelect";
import {
  Order,
  SortDirection,
  SupportTicket,
  SupportTicketSortFields,
  useGenerate_User_TokenMutation,
  useOrderLazyQuery,
  useSupport_TicketsQuery,
} from "@graphql/";
import { useTranslation } from "react-i18next";

import { Mail } from "@mui/icons-material";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Badge,
  SortDirection as MUISortDirection,
} from "@mui/material";
import { useApplicationContext } from "@src/context";
import {
  usePagination,
  useParams,
  useSupportTicketsMutations,
  useSupportTicketStatuses,
} from "@src/hooks";
import moment from "moment";
import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SupportTicketMobileRow } from "@components/MobileTableRows/SupportTicketMobileRow/SupportTicketMobileRow";
import { VerifyCustomerForm } from "@components/VerifyCustomerForm";
import { encodeParam } from "@utils/encodeParams";
import { useSupportTicketsFilter } from "./useSupportTicketsFilter";
import { Sort } from "@components/Table/TableHeader";
import { OrderDetails } from "@components/OrderDetails";
import { SuggestionForm } from "@components/SuggestionForm";
import { SUPPORT_TICKET_STATUSES, USER_TYPES } from "@utils/constants";

export const MyTickets: FC = () => {
  const { connectedUser, messagerie, notify, connectedUserType } =
    useApplicationContext();
  const { t } = useTranslation();
  const { params, requiredParams } = useParams();
  const navigate = useNavigate();
  const { getSupportTicketsFilter } = useSupportTicketsFilter(params);
  const [getOrder] = useOrderLazyQuery();
  const { pathname } = useLocation();
  const [showVerifyCustomerForm, setShowVerifyCustomerForm] =
    useState<boolean>(false);
  const [openSupportTicketDetails, setOpenShowSupportTicketDetails] =
    useState<boolean>(false);
  const [openUserSelectModal, setOpenUserSelectModal] =
    useState<boolean>(false);
  const [openSuggestionModal, setOpenSuggestionModal] =
    useState<boolean>(false);
  const [supportTicket, setSupportTicket] = useState<SupportTicket>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showOrderDetails, setShowOrderDetails] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const pagination = usePagination(false);
  const { supportTicketStatuses } = useSupportTicketStatuses();
  const { updateOneSupportTicket } = useSupportTicketsMutations();
  const [generateUserToken] = useGenerate_User_TokenMutation({
    onCompleted: (data) => {
      if (data.generateUserToken) {
        notify({
          message: t("serviceCustomer.customerVerification.success"),
          type: "success",
        });
        setShowVerifyCustomerForm(false);
      }
    },
    onError: (e) => {
      notify({
        message: e.message,
        type: "error",
      });
    },
  });

  const categories = {
    ORDER: t("supportTicket.list.categories.ORDER"),
    OTHERS: t("supportTicket.list.categories.OTHERS"),
  };

  const priorities = {
    LOW: t("supportTicket.list.priority.LOW"),
    MEDIUM: t("supportTicket.list.priority.MEDIUM"),
    HIGH: t("supportTicket.list.priority.HIGH"),
  };
  const {
    data,
    loading,
    fetchMore: fetchMoreSupportTickets,
  } = useSupport_TicketsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: getSupportTicketsFilter(),
      paging: pagination.offsetPaging,
      sorting: params.sortFields
        ? params.sortFields.split(",").map((sortField) => {
            const splits = sortField.split("__");
            return {
              field: splits[0] as SupportTicketSortFields,
              direction: splits[1].toUpperCase() as SortDirection,
            };
          })
        : undefined,
    },
    skip: !connectedUser,
    onCompleted: (data) => {
      pagination.setTotalCount(data.supportTickets.totalCount);
    },
  });

  const supportTickets = data?.supportTickets.nodes || [];

  const handleUpdateSupportTicketStatus = (value: string, id: string) => {
    updateOneSupportTicket({
      variables: { input: { id: id, update: { status: value } } },
    });
  };

  const serviceCustomerColumns =
    connectedUser?.role.code === "SERVICE_CUSTOMER"
      ? [
          {
            label: t("supportTicket.list.label.status"),
            name: "status",
            renderer: (row: SupportTicket) => {
              const handleChange = (value: string) => {
                handleUpdateSupportTicketStatus(value, row.id);
              };
              return (
                <ChipSelect
                  menuItems={supportTicketStatuses}
                  onChange={handleChange}
                  value={row.status}
                  width={100}
                />
              );
            },
          },
        ]
      : [];

  const columns: Column[] = [
    {
      label: t("supportTicket.list.label.ticketRef"),
      name: "ref",
    },
    {
      label: t("commons.category"),
      name: "category",
      renderer: (row: SupportTicket) => {
        return categories[row.category as keyof typeof categories];
      },
      sortable: true,
    },
    {
      label: t("supportTicket.form.priority"),
      name: "priority",
      renderer: (row: SupportTicket) => {
        return priorities[row.priority as keyof typeof priorities];
      },
      sortable: true,
    },
    {
      label: t("commons.orderRef"),
      name: "",
      renderer: (row: SupportTicket) => {
        return row.order?.ref || "-";
      },
    },
    {
      label: t("supportTicket.list.label.subject"),
      name: "subject",
    },
    {
      label: t("supportTicket.list.label.dateAdded"),
      name: "createdAt",
      renderer: (row: SupportTicket) => {
        return moment(row.createdAt).format("DD/MM/YYYY à hh:mm");
      },
      breaking: true,
    },
    {
      label: t("supportTicket.list.label.customer"),
      name: "",
      renderer: (row: SupportTicket) => {
        return row.customer
          ? `${row.customer?.firstName} ${row.customer?.lastName}`
          : t("commons.deletedUser");
      },
      breaking: true,
    },
    ...serviceCustomerColumns,
    {
      label: t("commons.actions"),
      name: "",
      renderer: (row: SupportTicket) => {
        const moreHorizMenus = [
          {
            label: t("commons.edit"),
            value: "UPDATE",
            disabled: connectedUser?.role.code === "SERVICE_CUSTOMER",
          },
          {
            label: t("commons.assign"),
            value: "ASSIGN",
          },
          {
            label: "Proposer une solution",
            value: "SUGGEST",
          },
          {
            label: "Voir la commande",
            value: "VIEW_ORDER",
            disabled: row.category !== "ORDER",
          },
          {
            label: t("commons.seeDetails"),
            value: "DETAILS",
          },
        ];
        const handleSelect = (selected: string) => {
          setSupportTicket(row);
          switch (selected) {
            case "UPDATE":
              setShowForm(true);
              break;
            case "DETAILS":
              setOpenShowSupportTicketDetails(true);
              break;
            case "ASSIGN":
              setOpenUserSelectModal(true);
              break;
            case "OPEN_MESSAGE":
              messagerie.setSupportTicket(row);
              break;
            case "VIEW_ORDER":
              viewOrder(row.idOrder!);
              break;
            case "SUGGEST":
              setOpenSuggestionModal(true);
              break;
            default:
              throw "selected not defined";
          }
        };
        return (
          <>
            <Badge badgeContent={row.unreadSupportTicketMessageCount}>
              <IconButton onClick={() => messagerie.setSupportTicket(row)}>
                <Mail />
              </IconButton>
            </Badge>
            <MoreHorizMenu
              disabled={!moreHorizMenus.length}
              items={moreHorizMenus}
              onSelect={handleSelect}
            />
          </>
        );
      },
    },
  ];

  const handleCloseForm = () => {
    setSupportTicket(undefined);
    setShowForm(false);
  };

  const handleCloseSupportTicketDetails = () => {
    setSupportTicket(undefined);
    setOpenShowSupportTicketDetails(false);
  };

  const viewOrder = async (idOrder: string) => {
    try {
      const order = await getOrder({ variables: { id: idOrder } });
      setOrder(order.data?.order as Order);
      setShowOrderDetails(true);
    } catch (e) {
      notify({ type: "error", message: "Une erreur s'est produite" });
    }
  };

  const handleSearch = (term: string) => {
    const query = encodeParam({
      ...requiredParams,
      offset: 0,
      term,
    });
    navigate(`${pathname}?${query}`);
  };

  const handleSort = (sortedColumns: Sort[]) => {
    const query = encodeParam({
      ...requiredParams,
      sortFields: sortedColumns
        .map(
          (sortedColumn) => `${sortedColumn.name}__${sortedColumn.direction}`
        )
        .join(","),
    });
    navigate(`${pathname}?${query}`);
  };

  const handleFetchMore = async () => {
    if (supportTickets.length < (data?.supportTickets?.totalCount || 0)) {
      fetchMoreSupportTickets({
        variables: {
          paging: { offset: supportTickets.length, limit: 10 },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            supportTickets: {
              ...prev.supportTickets,
              nodes: [
                ...prev.supportTickets.nodes,
                ...fetchMoreResult.supportTickets.nodes,
              ],
            },
          });
        },
      });
    }
  };

  const handleCloseVerifyCustomerForm = () => {
    setShowVerifyCustomerForm(false);
  };

  const handleSubmitVerifyCustomerForm = async (email: string) => {
    await generateUserToken({ variables: { email } });
  };

  const handleServiceCustomerAssignation = async (
    idServiceCustomer: string
  ) => {
    if (supportTicket) {
      await updateOneSupportTicket({
        variables: {
          input: {
            id: supportTicket.id,
            update: {
              idServiceCustomer: idServiceCustomer,
            },
          },
        },
      });
      setOpenUserSelectModal(false);
    }
  };

  return (
    <Box>
      {showVerifyCustomerForm && (
        <VerifyCustomerForm
          open={showVerifyCustomerForm}
          onClose={handleCloseVerifyCustomerForm}
          onSubmit={handleSubmitVerifyCustomerForm}
        />
      )}
      <UserSelect
        open={openUserSelectModal}
        setopen={setOpenUserSelectModal}
        onSelect={handleServiceCustomerAssignation}
        idSelected={supportTicket?.idServiceCustomer}
        title={t("supportTicket.form.assignment.title")}
        filter={{ role: { code: { eq: USER_TYPES.SERVICE_CUSTOMER } } }}
        subtitle={t("supportTicket.form.assignment.subtitle")}
      />
      {supportTicket && openSuggestionModal && (
        <SuggestionForm
          open={openSuggestionModal}
          onClose={() => setOpenSuggestionModal(false)}
          supportTicket={supportTicket}
        />
      )}
      <Table
        title={t("supportTicket.list.title")}
        columns={columns}
        data={supportTickets}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            {connectedUserType === "SERVICE_CUSTOMER" && (
              <Grid item xs={12} lg={3}>
                <Button
                  variant="outlined"
                  onClick={() => setShowVerifyCustomerForm(true)}
                  fullWidth
                >
                  {t("serviceCustomer.verifyCustomer")}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} lg={3}>
              <Button
                variant="contained"
                onClick={() => setShowForm(true)}
                fullWidth
              >
                {t("supportTicket.list.add")}
              </Button>
            </Grid>
          </Grid>
        }
        pagination={pagination}
        onSearch={handleSearch}
        mobileTableRow={<SupportTicketMobileRow />}
        model="supportTicket"
        onFetchMore={handleFetchMore}
        onSort={handleSort}
        sort={
          params.sortFields
            ? params.sortFields.split(",").map((sortField) => {
                const splits = sortField.split("__");
                return {
                  name: splits[0],
                  direction: splits[1] as MUISortDirection,
                };
              })
            : undefined
        }
        loading={loading}
        term={params.term}
      />
      {showForm && (
        <SupportTicketForm
          open={showForm}
          onClose={handleCloseForm}
          supportTicket={supportTicket}
        />
      )}
      {openSupportTicketDetails && (
        <SupportTicketDetails
          open={openSupportTicketDetails}
          supportTicket={supportTicket}
          onClose={handleCloseSupportTicketDetails}
        />
      )}
      {showOrderDetails && (
        <OrderDetails
          show={showOrderDetails}
          onClose={() => {
            setOrder(undefined);
            setShowOrderDetails(false);
          }}
          order={order}
        />
      )}
    </Box>
  );
};
