export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  orders?: Maybe<AddressOrdersConnection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  zipcode: Scalars['String']['output'];
};


export type AddressOrdersArgs = {
  paging?: OffsetPaging;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  nodes: Array<Address>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AddressDeleteFilter = {
  and?: InputMaybe<Array<AddressDeleteFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressDeleteFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  zipcode?: InputMaybe<StringFieldComparison>;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressFilter = {
  and?: InputMaybe<Array<AddressFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<AddressFilterUserFilter>;
  zipcode?: InputMaybe<StringFieldComparison>;
};

export type AddressFilterUserFilter = {
  and?: InputMaybe<Array<AddressFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AddressFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type AddressOrdersConnection = {
  __typename?: 'AddressOrdersConnection';
  nodes: Array<Order>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AddressSort = {
  direction: SortDirection;
  field: AddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddressSortFields {
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  IdUser = 'idUser',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Zipcode = 'zipcode'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  createdAt: Scalars['DateTime']['output'];
  files: Array<File>;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  payoutFrequency: Scalars['String']['output'];
  swift: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type BankAccountConnection = {
  __typename?: 'BankAccountConnection';
  nodes: Array<BankAccount>;
  pageInfo: OffsetPageInfo;
};

export type BankAccountDeleteResponse = {
  __typename?: 'BankAccountDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  payoutFrequency?: Maybe<Scalars['String']['output']>;
  swift?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BankAccountFilter = {
  and?: InputMaybe<Array<BankAccountFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  iban?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BankAccountFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<BankAccountFilterUserFilter>;
};

export type BankAccountFilterUserFilter = {
  and?: InputMaybe<Array<BankAccountFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BankAccountFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type BankAccountSort = {
  direction: SortDirection;
  field: BankAccountSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BankAccountSortFields {
  CreatedAt = 'createdAt',
  Iban = 'iban',
  UpdatedAt = 'updatedAt'
}

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CarteVitale = {
  __typename?: 'CarteVitale';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  id: Scalars['ID']['output'];
  idFile: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  info?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
  validationError?: Maybe<Scalars['String']['output']>;
};

export type CarteVitaleConnection = {
  __typename?: 'CarteVitaleConnection';
  nodes: Array<CarteVitale>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CarteVitaleFilter = {
  and?: InputMaybe<Array<CarteVitaleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  file?: InputMaybe<CarteVitaleFilterFileFilter>;
  or?: InputMaybe<Array<CarteVitaleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<CarteVitaleFilterUserFilter>;
};

export type CarteVitaleFilterFileFilter = {
  and?: InputMaybe<Array<CarteVitaleFilterFileFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CarteVitaleFilterFileFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CarteVitaleFilterUserFilter = {
  and?: InputMaybe<Array<CarteVitaleFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CarteVitaleFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CarteVitaleSort = {
  direction: SortDirection;
  field: CarteVitaleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CarteVitaleSortFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type CreateAddress = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

export type CreateBankAccount = {
  iban: Scalars['String']['input'];
  idUser: Scalars['ID']['input'];
  payoutFrequency?: InputMaybe<Scalars['String']['input']>;
  swift: Scalars['String']['input'];
};

export type CreateFeedback = {
  category: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  orderRef?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Float']['input'];
};

export type CreateInfoLegale = {
  name: Scalars['String']['input'];
  numTva: Scalars['String']['input'];
  sirenCode: Scalars['String']['input'];
};

export type CreateOneAddressInput = {
  address: CreateAddress;
};

export type CreateOneBankAccountInput = {
  bankAccount: CreateBankAccount;
};

export type CreateOneFeedbackInput = {
  feedback: CreateFeedback;
};

export type CreateOneInfoLegaleInput = {
  infoLegale: CreateInfoLegale;
};

export type CreateOneOpeningTimeInput = {
  openingTime: CreateOpeningTime;
};

export type CreateOneOrderAssignmentInput = {
  orderAssignment: CreateOrderAssignment;
};

export type CreateOneOrderInput = {
  order: CreateOrder;
};

export type CreateOneOrderItemInput = {
  orderItem: CreateOrderItem;
};

export type CreateOneStripeAccountInput = {
  stripeAccount: CreateStripeAccount;
};

export type CreateOneStripeAccountLinkInput = {
  stripeAccountLink: CreateStripeAccountLink;
};

export type CreateOneStripeCheckoutSessionInput = {
  stripeCheckoutSession: CreateStripeCheckoutSession;
};

export type CreateOneStripeTransfertInput = {
  stripeTransfert: CreateStripeTransfert;
};

export type CreateOneSupportTicketInput = {
  supportTicket: CreateSupportTicket;
};

export type CreateOneSupportTicketMessageInput = {
  supportTicketMessage: CreateSupportTicketMessage;
};

export type CreateOneTarificationInput = {
  tarification: CreateTarification;
};

export type CreateOneUserHistoryInput = {
  userHistory: CreateUserHistory;
};

export type CreateOneUserInput = {
  user: CreateUser;
};

export type CreateOpeningTime = {
  days: Scalars['JSON']['input'];
};

export type CreateOrder = {
  datetime: Scalars['DateTime']['input'];
  idAddress: Scalars['ID']['input'];
  idCarteVitale: Scalars['ID']['input'];
  idCustomer: Scalars['ID']['input'];
  idMutuelle: Scalars['ID']['input'];
  idOrdonnance: Scalars['ID']['input'];
  idVendor: Scalars['ID']['input'];
  reductionCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderAssignment = {
  idDeliveryMan: Scalars['ID']['input'];
  idOrder: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type CreateOrderItem = {
  description: Scalars['String']['input'];
  idOrder: Scalars['ID']['input'];
  libelle: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  refund: Scalars['Float']['input'];
  unitPrice: Scalars['Float']['input'];
};

export type CreateStripeAccount = {
  idUser: Scalars['ID']['input'];
};

export type CreateStripeAccountLink = {
  idStripeAccount: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type CreateStripeCheckoutSession = {
  idOrder: Scalars['ID']['input'];
};

export type CreateStripeTransfert = {
  idOrder: Scalars['ID']['input'];
  idUser: Scalars['ID']['input'];
};

export type CreateSupportTicket = {
  category: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  idCustomer: Scalars['ID']['input'];
  idOrder?: InputMaybe<Scalars['ID']['input']>;
  idServiceCustomer?: InputMaybe<Scalars['ID']['input']>;
  priority: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type CreateSupportTicketMessage = {
  idSender: Scalars['ID']['input'];
  idSupportTicket: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type CreateTarification = {
  dateDebut: Scalars['DateTime']['input'];
  dateFin: Scalars['DateTime']['input'];
  deliveryManPercentage: Scalars['Int']['input'];
  prixKmJour: Scalars['Int']['input'];
  prixKmSoir: Scalars['Int']['input'];
  tarifMinimum: Scalars['Int']['input'];
  tva: Scalars['Int']['input'];
};

export type CreateUser = {
  birthDate: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  idAdmin?: InputMaybe<Scalars['ID']['input']>;
  idEntity?: InputMaybe<Scalars['ID']['input']>;
  idRole: Scalars['ID']['input'];
  idVehicle?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  optionalPhone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserHistory = {
  action: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  idUser: Scalars['ID']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DeleteManyAddressesInput = {
  filter: AddressDeleteFilter;
};

export type DeleteManyFilesInput = {
  filter: FileDeleteFilter;
};

export type DeleteManyOrderItemsInput = {
  filter: OrderItemDeleteFilter;
};

export type DeleteManyOrdersInput = {
  filter: OrderDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  deletedCount: Scalars['Int']['output'];
};

export type DeleteOneAddressInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneBankAccountInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneFileInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneInfoLegaleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneOpeningTimeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneOrderInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneOrderItemInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneStripeAccountInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneTarificationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOneUserInput = {
  id: Scalars['ID']['input'];
};

export type Entity = {
  __typename?: 'Entity';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  category: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderRef?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  nodes: Array<Feedback>;
  pageInfo: OffsetPageInfo;
};

export type FeedbackFilter = {
  and?: InputMaybe<Array<FeedbackFilter>>;
  category?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<FeedbackFilter>>;
  orderRef?: InputMaybe<StringFieldComparison>;
  score?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FeedbackSort = {
  direction: SortDirection;
  field: FeedbackSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FeedbackSortFields {
  Category = 'category',
  CreatedAt = 'createdAt',
  OrderRef = 'orderRef',
  Score = 'score',
  UpdatedAt = 'updatedAt'
}

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idType?: Maybe<Scalars['ID']['output']>;
  idUser: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type FileConnection = {
  __typename?: 'FileConnection';
  nodes: Array<File>;
  pageInfo: OffsetPageInfo;
};

export type FileDeleteFilter = {
  and?: InputMaybe<Array<FileDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FileDeleteFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FileDeleteResponse = {
  __typename?: 'FileDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idType?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileFilter = {
  and?: InputMaybe<Array<FileFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FileFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FileSort = {
  direction: SortDirection;
  field: FileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FileSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type FileUpdateFilter = {
  and?: InputMaybe<Array<FileUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FileUpdateFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FloatFieldComparison = {
  between?: InputMaybe<FloatFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<FloatFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type InfoLegale = {
  __typename?: 'InfoLegale';
  addresses?: Maybe<Array<Address>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numTva: Scalars['String']['output'];
  sirenCode: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type InfoLegaleConnection = {
  __typename?: 'InfoLegaleConnection';
  nodes: Array<InfoLegale>;
  pageInfo: OffsetPageInfo;
};

export type InfoLegaleDeleteResponse = {
  __typename?: 'InfoLegaleDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numTva?: Maybe<Scalars['String']['output']>;
  sirenCode?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InfoLegaleFilter = {
  and?: InputMaybe<Array<InfoLegaleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  numTva?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InfoLegaleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<InfoLegaleFilterUserFilter>;
};

export type InfoLegaleFilterUserFilter = {
  and?: InputMaybe<Array<InfoLegaleFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InfoLegaleFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type InfoLegaleSort = {
  direction: SortDirection;
  field: InfoLegaleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InfoLegaleSortFields {
  CreatedAt = 'createdAt',
  Name = 'name',
  NumTva = 'numTva',
  UpdatedAt = 'updatedAt'
}

export type JsonFilterComparison = {
  eq?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  iLike?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  neq?: InputMaybe<Scalars['JSON']['input']>;
  notILike?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
  notLike?: InputMaybe<Scalars['JSON']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignDeliveryMan: Order;
  changeUserPassword: User;
  createOneAddress: Address;
  createOneBankAccount: BankAccount;
  createOneFeedback: Feedback;
  createOneInfoLegale: InfoLegale;
  createOneOpeningTime: OpeningTime;
  createOneOrder: Order;
  createOneOrderAssignment: OrderAssignment;
  createOneOrderItem: OrderItem;
  createOneStripeAccount: StripeAccount;
  createOneStripeAccountLink: StripeAccountLink;
  createOneStripeCheckoutSession: StripeCheckoutSession;
  createOneStripeTransfert: StripeTransfert;
  createOneSupportTicket: SupportTicket;
  createOneSupportTicketMessage: SupportTicketMessage;
  createOneTarification: Tarification;
  createOneUser: User;
  createOneUserHistory: UserHistory;
  createUserPassword: User;
  deleteManyAddresses: DeleteManyResponse;
  deleteManyFiles: DeleteManyResponse;
  deleteManyOrderItems: DeleteManyResponse;
  deleteManyOrders: DeleteManyResponse;
  deleteOneAddress: AddressDeleteResponse;
  deleteOneBankAccount: BankAccountDeleteResponse;
  deleteOneFile: FileDeleteResponse;
  deleteOneInfoLegale: InfoLegaleDeleteResponse;
  deleteOneOpeningTime: OpeningTimeDeleteResponse;
  deleteOneOrder: OrderDeleteResponse;
  deleteOneOrderItem: OrderItemDeleteResponse;
  deleteOneStripeAccount: StripeAccountDeleteResponse;
  deleteOneTarification: TarificationDeleteResponse;
  deleteOneUser: UserDeleteResponse;
  forgotPassword: Scalars['Boolean']['output'];
  generateUserToken: Scalars['Boolean']['output'];
  incrementStepCreation: User;
  refund: Order;
  regenerateToken: Scalars['String']['output'];
  retrieveCheckoutSession: StripeCheckoutSession;
  sendApplicationSupport: Scalars['Boolean']['output'];
  sendMyPosition: Scalars['Boolean']['output'];
  signup: User;
  updateManyFiles: UpdateManyResponse;
  updateManyNotifications: UpdateManyResponse;
  updateManySupportTicketMessages: UpdateManyResponse;
  updateManyUsers: UpdateManyResponse;
  updateOneAddress: Address;
  updateOneBankAccount: BankAccount;
  updateOneFile: File;
  updateOneInfoLegale: InfoLegale;
  updateOneNotification: Notification;
  updateOneOpeningTime: OpeningTime;
  updateOneOrder: Order;
  updateOneOrderAssignment: OrderAssignment;
  updateOneOrderItem: OrderItem;
  updateOneSupportTicket: SupportTicket;
  updateOneSupportTicketMessage: SupportTicketMessage;
  updateOneTarification: Tarification;
  updateOneUser: User;
  verifyDeliveryCode: Order;
  verifyReductionCode: Scalars['Int']['output'];
};


export type MutationAssignDeliveryManArgs = {
  idDeliveryMan: Scalars['String']['input'];
  idOrder: Scalars['String']['input'];
};


export type MutationChangeUserPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationCreateOneAddressArgs = {
  input: CreateOneAddressInput;
};


export type MutationCreateOneBankAccountArgs = {
  input: CreateOneBankAccountInput;
};


export type MutationCreateOneFeedbackArgs = {
  input: CreateOneFeedbackInput;
};


export type MutationCreateOneInfoLegaleArgs = {
  input: CreateOneInfoLegaleInput;
};


export type MutationCreateOneOpeningTimeArgs = {
  input: CreateOneOpeningTimeInput;
};


export type MutationCreateOneOrderArgs = {
  input: CreateOneOrderInput;
};


export type MutationCreateOneOrderAssignmentArgs = {
  input: CreateOneOrderAssignmentInput;
};


export type MutationCreateOneOrderItemArgs = {
  input: CreateOneOrderItemInput;
};


export type MutationCreateOneStripeAccountArgs = {
  input: CreateOneStripeAccountInput;
};


export type MutationCreateOneStripeAccountLinkArgs = {
  input: CreateOneStripeAccountLinkInput;
};


export type MutationCreateOneStripeCheckoutSessionArgs = {
  input: CreateOneStripeCheckoutSessionInput;
};


export type MutationCreateOneStripeTransfertArgs = {
  input: CreateOneStripeTransfertInput;
};


export type MutationCreateOneSupportTicketArgs = {
  input: CreateOneSupportTicketInput;
};


export type MutationCreateOneSupportTicketMessageArgs = {
  input: CreateOneSupportTicketMessageInput;
};


export type MutationCreateOneTarificationArgs = {
  input: CreateOneTarificationInput;
};


export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};


export type MutationCreateOneUserHistoryArgs = {
  input: CreateOneUserHistoryInput;
};


export type MutationCreateUserPasswordArgs = {
  idUser: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationDeleteManyAddressesArgs = {
  input: DeleteManyAddressesInput;
};


export type MutationDeleteManyFilesArgs = {
  input: DeleteManyFilesInput;
};


export type MutationDeleteManyOrderItemsArgs = {
  input: DeleteManyOrderItemsInput;
};


export type MutationDeleteManyOrdersArgs = {
  input: DeleteManyOrdersInput;
};


export type MutationDeleteOneAddressArgs = {
  input: DeleteOneAddressInput;
};


export type MutationDeleteOneBankAccountArgs = {
  input: DeleteOneBankAccountInput;
};


export type MutationDeleteOneFileArgs = {
  input: DeleteOneFileInput;
};


export type MutationDeleteOneInfoLegaleArgs = {
  input: DeleteOneInfoLegaleInput;
};


export type MutationDeleteOneOpeningTimeArgs = {
  input: DeleteOneOpeningTimeInput;
};


export type MutationDeleteOneOrderArgs = {
  input: DeleteOneOrderInput;
};


export type MutationDeleteOneOrderItemArgs = {
  input: DeleteOneOrderItemInput;
};


export type MutationDeleteOneStripeAccountArgs = {
  input: DeleteOneStripeAccountInput;
};


export type MutationDeleteOneTarificationArgs = {
  input: DeleteOneTarificationInput;
};


export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationGenerateUserTokenArgs = {
  email: Scalars['String']['input'];
};


export type MutationRefundArgs = {
  idOrder: Scalars['String']['input'];
};


export type MutationRegenerateTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRetrieveCheckoutSessionArgs = {
  idSession: Scalars['String']['input'];
};


export type MutationSendApplicationSupportArgs = {
  idDeliveryMan: Scalars['String']['input'];
  idOrder: Scalars['String']['input'];
};


export type MutationSendMyPositionArgs = {
  payload: SendMyPositionPayload;
};


export type MutationSignupArgs = {
  payload: SignupPayload;
};


export type MutationUpdateManyFilesArgs = {
  input: UpdateManyFilesInput;
};


export type MutationUpdateManyNotificationsArgs = {
  input: UpdateManyNotificationsInput;
};


export type MutationUpdateManySupportTicketMessagesArgs = {
  input: UpdateManySupportTicketMessagesInput;
};


export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};


export type MutationUpdateOneAddressArgs = {
  input: UpdateOneAddressInput;
};


export type MutationUpdateOneBankAccountArgs = {
  input: UpdateOneBankAccountInput;
};


export type MutationUpdateOneFileArgs = {
  input: UpdateOneFileInput;
};


export type MutationUpdateOneInfoLegaleArgs = {
  input: UpdateOneInfoLegaleInput;
};


export type MutationUpdateOneNotificationArgs = {
  input: UpdateOneNotificationInput;
};


export type MutationUpdateOneOpeningTimeArgs = {
  input: UpdateOneOpeningTimeInput;
};


export type MutationUpdateOneOrderArgs = {
  input: UpdateOneOrderInput;
};


export type MutationUpdateOneOrderAssignmentArgs = {
  input: UpdateOneOrderAssignmentInput;
};


export type MutationUpdateOneOrderItemArgs = {
  input: UpdateOneOrderItemInput;
};


export type MutationUpdateOneSupportTicketArgs = {
  input: UpdateOneSupportTicketInput;
};


export type MutationUpdateOneSupportTicketMessageArgs = {
  input: UpdateOneSupportTicketMessageInput;
};


export type MutationUpdateOneTarificationArgs = {
  input: UpdateOneTarificationInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationVerifyDeliveryCodeArgs = {
  deliveryCode: Scalars['String']['input'];
  idOrder: Scalars['String']['input'];
};


export type MutationVerifyReductionCodeArgs = {
  code: Scalars['String']['input'];
};

export type Mutuelle = {
  __typename?: 'Mutuelle';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  id: Scalars['ID']['output'];
  idFile: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  info?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
  validationError?: Maybe<Scalars['String']['output']>;
};

export type MutuelleConnection = {
  __typename?: 'MutuelleConnection';
  nodes: Array<Mutuelle>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MutuelleFilter = {
  and?: InputMaybe<Array<MutuelleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  file?: InputMaybe<MutuelleFilterFileFilter>;
  or?: InputMaybe<Array<MutuelleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<MutuelleFilterUserFilter>;
};

export type MutuelleFilterFileFilter = {
  and?: InputMaybe<Array<MutuelleFilterFileFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MutuelleFilterFileFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type MutuelleFilterUserFilter = {
  and?: InputMaybe<Array<MutuelleFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MutuelleFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type MutuelleSort = {
  direction: SortDirection;
  field: MutuelleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MutuelleSortFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type Notification = {
  __typename?: 'Notification';
  action: Scalars['String']['output'];
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  isDone?: Maybe<Scalars['Boolean']['output']>;
  status: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  nodes: Array<Notification>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type NotificationFilter = {
  and?: InputMaybe<Array<NotificationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idUser?: InputMaybe<StringFieldComparison>;
  isDone?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<NotificationFilter>>;
  status?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<NotificationFilterUserFilter>;
};

export type NotificationFilterUserFilter = {
  and?: InputMaybe<Array<NotificationFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<NotificationFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type NotificationSort = {
  direction: SortDirection;
  field: NotificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NotificationSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IdUser = 'idUser',
  IsDone = 'isDone',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type NotificationUpdateFilter = {
  and?: InputMaybe<Array<NotificationUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idUser?: InputMaybe<StringFieldComparison>;
  isDone?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<NotificationUpdateFilter>>;
  status?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OpeningTime = {
  __typename?: 'OpeningTime';
  createdAt: Scalars['DateTime']['output'];
  days: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type OpeningTimeConnection = {
  __typename?: 'OpeningTimeConnection';
  nodes: Array<OpeningTime>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OpeningTimeDeleteResponse = {
  __typename?: 'OpeningTimeDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  days?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OpeningTimeFilter = {
  and?: InputMaybe<Array<OpeningTimeFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  days?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<OpeningTimeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<OpeningTimeFilterUserFilter>;
};

export type OpeningTimeFilterUserFilter = {
  and?: InputMaybe<Array<OpeningTimeFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OpeningTimeFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OpeningTimeSort = {
  direction: SortDirection;
  field: OpeningTimeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OpeningTimeSortFields {
  CreatedAt = 'createdAt',
  Days = 'days',
  UpdatedAt = 'updatedAt'
}

export type Order = {
  __typename?: 'Order';
  address?: Maybe<Address>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  carteVitale?: Maybe<CarteVitale>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<User>;
  datetime: Scalars['DateTime']['output'];
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryCode?: Maybe<Scalars['String']['output']>;
  deliveryMan?: Maybe<User>;
  deliveryPrice: Scalars['Float']['output'];
  files?: Maybe<Array<File>>;
  id: Scalars['ID']['output'];
  idAddress?: Maybe<Scalars['ID']['output']>;
  idCarteVitale?: Maybe<Scalars['ID']['output']>;
  idCustomer?: Maybe<Scalars['ID']['output']>;
  idDeliveryMan?: Maybe<Scalars['ID']['output']>;
  idMutuelle?: Maybe<Scalars['ID']['output']>;
  idOrdonnance?: Maybe<Scalars['ID']['output']>;
  idVendor?: Maybe<Scalars['ID']['output']>;
  increaseDeliveryPricePercentage: Scalars['Int']['output'];
  livreurPaid: Scalars['Boolean']['output'];
  maxDeliveryPrice: Scalars['Float']['output'];
  mutuelle?: Maybe<Mutuelle>;
  orderAssignments?: Maybe<Array<OrderAssignment>>;
  orderHistories?: Maybe<Array<OrderHistory>>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderTarification: OrderTarification;
  ordonnance?: Maybe<Ordonnance>;
  pharmacyPaid: Scalars['Boolean']['output'];
  preparer?: Maybe<Scalars['String']['output']>;
  reduction?: Maybe<Scalars['Int']['output']>;
  ref: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stripePayouts?: Maybe<Array<StripePayout>>;
  stripeTransferts?: Maybe<Array<StripeTransfert>>;
  totalPrice: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vendor?: Maybe<User>;
  vendorPrice: Scalars['Float']['output'];
};

export type OrderAssignment = {
  __typename?: 'OrderAssignment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idDeliveryMan: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderAssignmentConnection = {
  __typename?: 'OrderAssignmentConnection';
  nodes: Array<OrderAssignment>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderAssignmentFilter = {
  and?: InputMaybe<Array<OrderAssignmentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderAssignmentFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrderAssignmentSort = {
  direction: SortDirection;
  field: OrderAssignmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrderAssignmentSortFields {
  CreatedAt = 'createdAt',
  IdOrder = 'idOrder',
  UpdatedAt = 'updatedAt'
}

export type OrderConnection = {
  __typename?: 'OrderConnection';
  nodes: Array<Order>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderDeleteFilter = {
  address_city?: InputMaybe<StringFieldComparison>;
  address_name?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OrderDeleteFilter>>;
  cancelReason?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer_firstName?: InputMaybe<StringFieldComparison>;
  datetime?: InputMaybe<DateFieldComparison>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  deliveryMan_firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idAddress?: InputMaybe<IdFilterComparison>;
  idCarteVitale?: InputMaybe<IdFilterComparison>;
  idCustomer?: InputMaybe<IdFilterComparison>;
  idDeliveryMan?: InputMaybe<IdFilterComparison>;
  idMutuelle?: InputMaybe<IdFilterComparison>;
  idOrdonnance?: InputMaybe<IdFilterComparison>;
  idVendor?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderDeleteFilter>>;
  preparer?: InputMaybe<StringFieldComparison>;
  ref?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  totalPrice?: InputMaybe<FloatFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vendor_firstName?: InputMaybe<StringFieldComparison>;
  vendor_infoLegale_name?: InputMaybe<StringFieldComparison>;
};

export type OrderDeleteResponse = {
  __typename?: 'OrderDeleteResponse';
  cancelReason?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  datetime?: Maybe<Scalars['DateTime']['output']>;
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryCode?: Maybe<Scalars['String']['output']>;
  deliveryPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idAddress?: Maybe<Scalars['ID']['output']>;
  idCarteVitale?: Maybe<Scalars['ID']['output']>;
  idCustomer?: Maybe<Scalars['ID']['output']>;
  idDeliveryMan?: Maybe<Scalars['ID']['output']>;
  idMutuelle?: Maybe<Scalars['ID']['output']>;
  idOrdonnance?: Maybe<Scalars['ID']['output']>;
  idVendor?: Maybe<Scalars['ID']['output']>;
  increaseDeliveryPricePercentage?: Maybe<Scalars['Int']['output']>;
  maxDeliveryPrice?: Maybe<Scalars['Float']['output']>;
  preparer?: Maybe<Scalars['String']['output']>;
  reduction?: Maybe<Scalars['Int']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vendorPrice?: Maybe<Scalars['Float']['output']>;
};

export type OrderFilter = {
  address?: InputMaybe<OrderFilterAddressFilter>;
  address_city?: InputMaybe<StringFieldComparison>;
  address_name?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<OrderFilter>>;
  cancelReason?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<OrderFilterUserFilter>;
  customer_firstName?: InputMaybe<StringFieldComparison>;
  datetime?: InputMaybe<DateFieldComparison>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  deliveryMan?: InputMaybe<OrderFilterUserFilter>;
  deliveryMan_firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idAddress?: InputMaybe<IdFilterComparison>;
  idCarteVitale?: InputMaybe<IdFilterComparison>;
  idCustomer?: InputMaybe<IdFilterComparison>;
  idDeliveryMan?: InputMaybe<IdFilterComparison>;
  idMutuelle?: InputMaybe<IdFilterComparison>;
  idOrdonnance?: InputMaybe<IdFilterComparison>;
  idVendor?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderFilter>>;
  preparer?: InputMaybe<StringFieldComparison>;
  ref?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  totalPrice?: InputMaybe<FloatFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vendor?: InputMaybe<OrderFilterUserFilter>;
  vendor_firstName?: InputMaybe<StringFieldComparison>;
  vendor_infoLegale_name?: InputMaybe<StringFieldComparison>;
};

export type OrderFilterAddressFilter = {
  and?: InputMaybe<Array<OrderFilterAddressFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrderFilterAddressFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  zipcode?: InputMaybe<StringFieldComparison>;
};

export type OrderFilterUserFilter = {
  and?: InputMaybe<Array<OrderFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrderFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  action: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderHistoryConnection = {
  __typename?: 'OrderHistoryConnection';
  nodes: Array<OrderHistory>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderHistoryFilter = {
  and?: InputMaybe<Array<OrderHistoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderHistoryFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrderHistorySort = {
  direction: SortDirection;
  field: OrderHistorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrderHistorySortFields {
  CreatedAt = 'createdAt',
  IdOrder = 'idOrder',
  UpdatedAt = 'updatedAt'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  libelle: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  refund: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  nodes: Array<OrderItem>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderItemDeleteFilter = {
  and?: InputMaybe<Array<OrderItemDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  libelle?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrderItemDeleteFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrderItemDeleteResponse = {
  __typename?: 'OrderItemDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idOrder?: Maybe<Scalars['ID']['output']>;
  libelle?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  refund?: Maybe<Scalars['Float']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderItemFilter = {
  and?: InputMaybe<Array<OrderItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  libelle?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrderItemFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrderItemSort = {
  direction: SortDirection;
  field: OrderItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrderItemSortFields {
  CreatedAt = 'createdAt',
  Description = 'description',
  IdOrder = 'idOrder',
  Libelle = 'libelle',
  UpdatedAt = 'updatedAt'
}

export type OrderSort = {
  direction: SortDirection;
  field: OrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrderSortFields {
  AddressCity = 'address_city',
  AddressName = 'address_name',
  CancelReason = 'cancelReason',
  CreatedAt = 'createdAt',
  CustomerFirstName = 'customer_firstName',
  Datetime = 'datetime',
  DeliveryCode = 'deliveryCode',
  DeliveryManFirstName = 'deliveryMan_firstName',
  Id = 'id',
  IdAddress = 'idAddress',
  IdCarteVitale = 'idCarteVitale',
  IdCustomer = 'idCustomer',
  IdDeliveryMan = 'idDeliveryMan',
  IdMutuelle = 'idMutuelle',
  IdOrdonnance = 'idOrdonnance',
  IdVendor = 'idVendor',
  Preparer = 'preparer',
  Ref = 'ref',
  Status = 'status',
  TotalPrice = 'totalPrice',
  UpdatedAt = 'updatedAt',
  VendorFirstName = 'vendor_firstName',
  VendorInfoLegaleName = 'vendor_infoLegale_name'
}

export type OrderStatusesCountsInput = {
  entityCode: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  idAdmin?: InputMaybe<Scalars['ID']['input']>;
  preparer?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};

export type OrderTarification = {
  __typename?: 'OrderTarification';
  createdAt: Scalars['DateTime']['output'];
  deliveryManPercentage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  prixKmJour: Scalars['Int']['output'];
  prixKmSoir: Scalars['Int']['output'];
  tarifMinimum: Scalars['Int']['output'];
  tva: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderTarificationConnection = {
  __typename?: 'OrderTarificationConnection';
  nodes: Array<OrderTarification>;
  pageInfo: OffsetPageInfo;
};

export type OrderTarificationFilter = {
  and?: InputMaybe<Array<OrderTarificationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderTarificationFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrderTarificationSort = {
  direction: SortDirection;
  field: OrderTarificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrderTarificationSortFields {
  CreatedAt = 'createdAt',
  IdOrder = 'idOrder',
  UpdatedAt = 'updatedAt'
}

export type Ordonnance = {
  __typename?: 'Ordonnance';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  id: Scalars['ID']['output'];
  idFile: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  info?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
  validationError?: Maybe<Scalars['String']['output']>;
};

export type OrdonnanceConnection = {
  __typename?: 'OrdonnanceConnection';
  nodes: Array<Ordonnance>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrdonnanceFilter = {
  and?: InputMaybe<Array<OrdonnanceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  file?: InputMaybe<OrdonnanceFilterFileFilter>;
  or?: InputMaybe<Array<OrdonnanceFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<OrdonnanceFilterUserFilter>;
};

export type OrdonnanceFilterFileFilter = {
  and?: InputMaybe<Array<OrdonnanceFilterFileFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrdonnanceFilterFileFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrdonnanceFilterUserFilter = {
  and?: InputMaybe<Array<OrdonnanceFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrdonnanceFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type OrdonnanceSort = {
  direction: SortDirection;
  field: OrdonnanceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrdonnanceSortFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type Query = {
  __typename?: 'Query';
  address: Address;
  addresses: AddressConnection;
  availableDeliveryMans: Array<User>;
  bankAccount: BankAccount;
  bankAccounts: BankAccountConnection;
  carteVitale: CarteVitale;
  carteVitales: CarteVitaleConnection;
  createPayzenPayment: Scalars['JSON']['output'];
  feedback: Feedback;
  feedbacks: FeedbackConnection;
  file: File;
  files: FileConnection;
  findOpenedEntity: Array<InfoLegale>;
  generalStatistics: Scalars['JSON']['output'];
  infoLegale: InfoLegale;
  infoLegales: InfoLegaleConnection;
  livreurDashboardPeriodicStats: Scalars['JSON']['output'];
  mutuelle: Mutuelle;
  mutuelles: MutuelleConnection;
  notification: Notification;
  notifications: NotificationConnection;
  openingTime: OpeningTime;
  openingTimes: OpeningTimeConnection;
  order: Order;
  orderAssignment: OrderAssignment;
  orderAssignments: OrderAssignmentConnection;
  orderHistories: OrderHistoryConnection;
  orderHistory: OrderHistory;
  orderItem: OrderItem;
  orderItems: OrderItemConnection;
  orderStatistics: Scalars['JSON']['output'];
  orderStatusesCounts: Scalars['JSON']['output'];
  orderTarification: OrderTarification;
  orderTarifications: OrderTarificationConnection;
  orders: OrderConnection;
  ordonnance: Ordonnance;
  ordonnances: OrdonnanceConnection;
  pharmacyDashboardPeriodicStats: Scalars['JSON']['output'];
  reduction: Reduction;
  reductions: ReductionConnection;
  resend2faCode: Scalars['Boolean']['output'];
  role: Role;
  roles: RoleConnection;
  scanImage: Scalars['String']['output'];
  serviceCustomerDashboardPeriodicStats: Scalars['JSON']['output'];
  signin: Scalars['String']['output'];
  stripeAccount: StripeAccount;
  stripeAccountHistories: StripeAccountHistoryConnection;
  stripeAccountHistory: StripeAccountHistory;
  stripeAccountLink: StripeAccountLink;
  stripeAccountLinks: StripeAccountLinkConnection;
  stripeAccountStatusesCounts: Scalars['JSON']['output'];
  stripeAccounts: StripeAccountConnection;
  stripeCheckoutSession: StripeCheckoutSession;
  stripeCheckoutSessions: StripeCheckoutSessionConnection;
  stripeEvent: StripeEvent;
  stripeEvents: StripeEventConnection;
  stripePayout: StripePayout;
  stripePayouts: StripePayoutConnection;
  stripeTransfert: StripeTransfert;
  stripeTransferts: StripeTransfertConnection;
  superadminDashboardGlobalStats: Scalars['JSON']['output'];
  superadminDashboardPeriodicStats: Scalars['JSON']['output'];
  supportTicket: SupportTicket;
  supportTicketHistories: SupportTicketHistoryConnection;
  supportTicketHistory: SupportTicketHistory;
  supportTicketMessage: SupportTicketMessage;
  supportTicketMessages: SupportTicketMessageConnection;
  supportTickets: SupportTicketConnection;
  supportTicketsCounts: Scalars['JSON']['output'];
  tarification: Tarification;
  tarifications: TarificationConnection;
  user: User;
  userHistories: UserHistoryConnection;
  userHistory: UserHistory;
  users: UserConnection;
  usersCount: Scalars['JSON']['output'];
  vehicle: Vehicle;
  vehicles: VehicleConnection;
  verify2faCode: SigninPayload;
  verifyBearerToken: User;
  verifyToken: User;
};


export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAddressesArgs = {
  filter?: AddressFilter;
  paging?: OffsetPaging;
  sorting?: Array<AddressSort>;
};


export type QueryAvailableDeliveryMansArgs = {
  idOrder: Scalars['String']['input'];
};


export type QueryBankAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBankAccountsArgs = {
  filter?: BankAccountFilter;
  paging?: OffsetPaging;
  sorting?: Array<BankAccountSort>;
};


export type QueryCarteVitaleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCarteVitalesArgs = {
  filter?: CarteVitaleFilter;
  paging?: OffsetPaging;
  sorting?: Array<CarteVitaleSort>;
};


export type QueryCreatePayzenPaymentArgs = {
  idOrder: Scalars['String']['input'];
};


export type QueryFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeedbacksArgs = {
  filter?: FeedbackFilter;
  paging?: OffsetPaging;
  sorting?: Array<FeedbackSort>;
};


export type QueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFilesArgs = {
  filter?: FileFilter;
  paging?: OffsetPaging;
  sorting?: Array<FileSort>;
};


export type QueryFindOpenedEntityArgs = {
  day: Scalars['Float']['input'];
  idOriginAddress: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
  time: Scalars['String']['input'];
};


export type QueryGeneralStatisticsArgs = {
  dateRange: Scalars['String']['input'];
};


export type QueryInfoLegaleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInfoLegalesArgs = {
  filter?: InfoLegaleFilter;
  paging?: OffsetPaging;
  sorting?: Array<InfoLegaleSort>;
};


export type QueryLivreurDashboardPeriodicStatsArgs = {
  idDeliveryMan: Scalars['String']['input'];
  period: Scalars['String']['input'];
};


export type QueryMutuelleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMutuellesArgs = {
  filter?: MutuelleFilter;
  paging?: OffsetPaging;
  sorting?: Array<MutuelleSort>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  filter?: NotificationFilter;
  paging?: OffsetPaging;
  sorting?: Array<NotificationSort>;
};


export type QueryOpeningTimeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOpeningTimesArgs = {
  filter?: OpeningTimeFilter;
  paging?: OffsetPaging;
  sorting?: Array<OpeningTimeSort>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderAssignmentsArgs = {
  filter?: OrderAssignmentFilter;
  paging?: OffsetPaging;
  sorting?: Array<OrderAssignmentSort>;
};


export type QueryOrderHistoriesArgs = {
  filter?: OrderHistoryFilter;
  paging?: OffsetPaging;
  sorting?: Array<OrderHistorySort>;
};


export type QueryOrderHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderItemsArgs = {
  filter?: OrderItemFilter;
  paging?: OffsetPaging;
  sorting?: Array<OrderItemSort>;
};


export type QueryOrderStatisticsArgs = {
  dateRange: Scalars['String']['input'];
};


export type QueryOrderStatusesCountsArgs = {
  input: OrderStatusesCountsInput;
};


export type QueryOrderTarificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderTarificationsArgs = {
  filter?: OrderTarificationFilter;
  paging?: OffsetPaging;
  sorting?: Array<OrderTarificationSort>;
};


export type QueryOrdersArgs = {
  filter?: OrderFilter;
  paging?: OffsetPaging;
  sorting?: Array<OrderSort>;
};


export type QueryOrdonnanceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdonnancesArgs = {
  filter?: OrdonnanceFilter;
  paging?: OffsetPaging;
  sorting?: Array<OrdonnanceSort>;
};


export type QueryPharmacyDashboardPeriodicStatsArgs = {
  idVendor: Scalars['String']['input'];
  period: Scalars['String']['input'];
};


export type QueryReductionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReductionsArgs = {
  filter?: ReductionFilter;
  paging?: OffsetPaging;
  sorting?: Array<ReductionSort>;
};


export type QueryResend2faCodeArgs = {
  idUser: Scalars['String']['input'];
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolesArgs = {
  filter?: RoleFilter;
  paging?: OffsetPaging;
  sorting?: Array<RoleSort>;
};


export type QueryServiceCustomerDashboardPeriodicStatsArgs = {
  idServiceCustomer: Scalars['String']['input'];
  period: Scalars['String']['input'];
};


export type QuerySigninArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type QueryStripeAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeAccountHistoriesArgs = {
  filter?: StripeAccountHistoryFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripeAccountHistorySort>;
};


export type QueryStripeAccountHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeAccountLinkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeAccountLinksArgs = {
  filter?: StripeAccountLinkFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripeAccountLinkSort>;
};


export type QueryStripeAccountsArgs = {
  filter?: StripeAccountFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripeAccountSort>;
};


export type QueryStripeCheckoutSessionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeCheckoutSessionsArgs = {
  filter?: StripeCheckoutSessionFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripeCheckoutSessionSort>;
};


export type QueryStripeEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeEventsArgs = {
  filter?: StripeEventFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripeEventSort>;
};


export type QueryStripePayoutArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripePayoutsArgs = {
  filter?: StripePayoutFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripePayoutSort>;
};


export type QueryStripeTransfertArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeTransfertsArgs = {
  filter?: StripeTransfertFilter;
  paging?: OffsetPaging;
  sorting?: Array<StripeTransfertSort>;
};


export type QuerySuperadminDashboardPeriodicStatsArgs = {
  period: Scalars['String']['input'];
};


export type QuerySupportTicketArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySupportTicketHistoriesArgs = {
  filter?: SupportTicketHistoryFilter;
  paging?: OffsetPaging;
  sorting?: Array<SupportTicketHistorySort>;
};


export type QuerySupportTicketHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySupportTicketMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySupportTicketMessagesArgs = {
  filter?: SupportTicketMessageFilter;
  paging?: OffsetPaging;
  sorting?: Array<SupportTicketMessageSort>;
};


export type QuerySupportTicketsArgs = {
  filter?: SupportTicketFilter;
  paging?: OffsetPaging;
  sorting?: Array<SupportTicketSort>;
};


export type QueryTarificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTarificationsArgs = {
  filter?: TarificationFilter;
  paging?: OffsetPaging;
  sorting?: Array<TarificationSort>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserHistoriesArgs = {
  filter?: UserHistoryFilter;
  paging?: OffsetPaging;
  sorting?: Array<UserHistorySort>;
};


export type QueryUserHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  filter?: UserFilter;
  paging?: OffsetPaging;
  sorting?: Array<UserSort>;
};


export type QueryVehicleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVehiclesArgs = {
  filter?: VehicleFilter;
  paging?: OffsetPaging;
  sorting?: Array<VehicleSort>;
};


export type QueryVerify2faCodeArgs = {
  code: Scalars['String']['input'];
  idUser: Scalars['String']['input'];
};


export type QueryVerifyTokenArgs = {
  action: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Reduction = {
  __typename?: 'Reduction';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerEmail?: Maybe<Scalars['String']['output']>;
  expirationDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isUsed: Scalars['Boolean']['output'];
  percentage: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReductionConnection = {
  __typename?: 'ReductionConnection';
  nodes: Array<Reduction>;
  pageInfo: OffsetPageInfo;
};

export type ReductionFilter = {
  and?: InputMaybe<Array<ReductionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<ReductionFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ReductionSort = {
  direction: SortDirection;
  field: ReductionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ReductionSortFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type Role = {
  __typename?: 'Role';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  nodes: Array<Role>;
  pageInfo: OffsetPageInfo;
};

export type RoleFilter = {
  and?: InputMaybe<Array<RoleFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<RoleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type RoleSort = {
  direction: SortDirection;
  field: RoleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RoleSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type SendMyPositionPayload = {
  idOrder: Scalars['ID']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type SigninPayload = {
  __typename?: 'SigninPayload';
  token: Scalars['String']['output'];
  user: User;
};

export type SignupPayload = {
  birthDate: Scalars['DateTime']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optionalPhone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile: Scalars['String']['input'];
  profession?: InputMaybe<Scalars['String']['input']>;
  roleCode?: Scalars['String']['input'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  account: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  stripeAccountLinks?: Maybe<Array<StripeAccountLink>>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
  user: User;
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type StripeAccountStripeAccountLinksArgs = {
  filter?: StripeAccountLinkFilter;
  sorting?: Array<StripeAccountLinkSort>;
};

export type StripeAccountConnection = {
  __typename?: 'StripeAccountConnection';
  nodes: Array<StripeAccount>;
  pageInfo: OffsetPageInfo;
};

export type StripeAccountDeleteResponse = {
  __typename?: 'StripeAccountDeleteResponse';
  account?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StripeAccountFilter = {
  account?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<StripeAccountFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<StripeAccountFilter>>;
  status?: InputMaybe<StringFieldComparison>;
  stripeAccountLink?: InputMaybe<StripeAccountFilterStripeAccountLinkFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  user?: InputMaybe<StripeAccountFilterUserFilter>;
};

export type StripeAccountFilterStripeAccountLinkFilter = {
  and?: InputMaybe<Array<StripeAccountFilterStripeAccountLinkFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<StripeAccountFilterStripeAccountLinkFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  url?: InputMaybe<StringFieldComparison>;
};

export type StripeAccountFilterUserFilter = {
  and?: InputMaybe<Array<StripeAccountFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StripeAccountFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripeAccountHistory = {
  __typename?: 'StripeAccountHistory';
  action: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idStripeAccount: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type StripeAccountHistoryConnection = {
  __typename?: 'StripeAccountHistoryConnection';
  nodes: Array<StripeAccountHistory>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StripeAccountHistoryFilter = {
  and?: InputMaybe<Array<StripeAccountHistoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idStripeAccount?: InputMaybe<IdFilterComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StripeAccountHistoryFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripeAccountHistorySort = {
  direction: SortDirection;
  field: StripeAccountHistorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripeAccountHistorySortFields {
  CreatedAt = 'createdAt',
  IdStripeAccount = 'idStripeAccount',
  IdUser = 'idUser',
  UpdatedAt = 'updatedAt'
}

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idStripeAccount: Scalars['ID']['output'];
  stripeAccount: StripeAccount;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type StripeAccountLinkConnection = {
  __typename?: 'StripeAccountLinkConnection';
  nodes: Array<StripeAccountLink>;
  pageInfo: OffsetPageInfo;
};

export type StripeAccountLinkFilter = {
  and?: InputMaybe<Array<StripeAccountLinkFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<StripeAccountLinkFilter>>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  url?: InputMaybe<StringFieldComparison>;
};

export type StripeAccountLinkSort = {
  direction: SortDirection;
  field: StripeAccountLinkSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripeAccountLinkSortFields {
  CreatedAt = 'createdAt',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export type StripeAccountSort = {
  direction: SortDirection;
  field: StripeAccountSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripeAccountSortFields {
  Account = 'account',
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  idSession: Scalars['String']['output'];
  order: Order;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type StripeCheckoutSessionConnection = {
  __typename?: 'StripeCheckoutSessionConnection';
  nodes: Array<StripeCheckoutSession>;
  pageInfo: OffsetPageInfo;
};

export type StripeCheckoutSessionFilter = {
  and?: InputMaybe<Array<StripeCheckoutSessionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idSession?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StripeCheckoutSessionFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripeCheckoutSessionSort = {
  direction: SortDirection;
  field: StripeCheckoutSessionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripeCheckoutSessionSortFields {
  CreatedAt = 'createdAt',
  IdSession = 'idSession',
  UpdatedAt = 'updatedAt'
}

export type StripeEvent = {
  __typename?: 'StripeEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idEvent: Scalars['String']['output'];
  idStripeAccount: Scalars['ID']['output'];
  stripeAccount: StripeAccount;
  updatedAt: Scalars['DateTime']['output'];
};

export type StripeEventConnection = {
  __typename?: 'StripeEventConnection';
  nodes: Array<StripeEvent>;
  pageInfo: OffsetPageInfo;
};

export type StripeEventFilter = {
  and?: InputMaybe<Array<StripeEventFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idStripeAccount?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StripeEventFilter>>;
  stripeAccount?: InputMaybe<StripeEventFilterStripeAccountFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripeEventFilterStripeAccountFilter = {
  account?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<StripeEventFilterStripeAccountFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<StripeEventFilterStripeAccountFilter>>;
  status?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripeEventSort = {
  direction: SortDirection;
  field: StripeEventSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripeEventSortFields {
  CreatedAt = 'createdAt',
  IdStripeAccount = 'idStripeAccount',
  UpdatedAt = 'updatedAt'
}

export type StripePayout = {
  __typename?: 'StripePayout';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  entity: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  idPayout: Scalars['String']['output'];
  idStripeAccount?: Maybe<Scalars['ID']['output']>;
  idUser?: Maybe<Scalars['ID']['output']>;
  order: Order;
  stripeAccount?: Maybe<StripeAccount>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type StripePayoutConnection = {
  __typename?: 'StripePayoutConnection';
  nodes: Array<StripePayout>;
  pageInfo: OffsetPageInfo;
};

export type StripePayoutFilter = {
  and?: InputMaybe<Array<StripePayoutFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  idStripeAccount?: InputMaybe<IdFilterComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StripePayoutFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripePayoutSort = {
  direction: SortDirection;
  field: StripePayoutSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripePayoutSortFields {
  CreatedAt = 'createdAt',
  IdOrder = 'idOrder',
  IdStripeAccount = 'idStripeAccount',
  IdUser = 'idUser',
  UpdatedAt = 'updatedAt'
}

export type StripeTransfert = {
  __typename?: 'StripeTransfert';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  entity: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idOrder: Scalars['ID']['output'];
  idStripeAccount: Scalars['ID']['output'];
  idTransfert: Scalars['String']['output'];
  idUser: Scalars['ID']['output'];
  order: Order;
  stripeAccount: StripeAccount;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type StripeTransfertConnection = {
  __typename?: 'StripeTransfertConnection';
  nodes: Array<StripeTransfert>;
  pageInfo: OffsetPageInfo;
};

export type StripeTransfertFilter = {
  and?: InputMaybe<Array<StripeTransfertFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  idStripeAccount?: InputMaybe<IdFilterComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<StripeTransfertFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type StripeTransfertSort = {
  direction: SortDirection;
  field: StripeTransfertSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StripeTransfertSortFields {
  CreatedAt = 'createdAt',
  IdOrder = 'idOrder',
  IdStripeAccount = 'idStripeAccount',
  IdUser = 'idUser',
  UpdatedAt = 'updatedAt'
}

export type Subscription = {
  __typename?: 'Subscription';
  findDeliveryManPosition: Scalars['JSON']['output'];
  notificationSubscription: Scalars['JSON']['output'];
  receiveApplicationSupport: Scalars['JSON']['output'];
  supportTicketChanged: SupportTicket;
  supportTicketMessageCreated: SupportTicketMessage;
};


export type SubscriptionFindDeliveryManPositionArgs = {
  idUser: Scalars['String']['input'];
};


export type SubscriptionNotificationSubscriptionArgs = {
  idUser: Scalars['String']['input'];
};

export type SupportTicket = {
  __typename?: 'SupportTicket';
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<File>>;
  id: Scalars['ID']['output'];
  idCustomer: Scalars['ID']['output'];
  idOrder?: Maybe<Scalars['ID']['output']>;
  idServiceCustomer?: Maybe<Scalars['ID']['output']>;
  order?: Maybe<Order>;
  priority: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  serviceCustomer?: Maybe<User>;
  status: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  suggestion?: Maybe<Scalars['JSON']['output']>;
  supportTicketHistories?: Maybe<Array<SupportTicketHistory>>;
  supportTicketMessages?: Maybe<Array<SupportTicketMessage>>;
  unreadSupportTicketMessageCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SupportTicketConnection = {
  __typename?: 'SupportTicketConnection';
  nodes: Array<SupportTicket>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SupportTicketFilter = {
  and?: InputMaybe<Array<SupportTicketFilter>>;
  category?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<SupportTicketFilterUserFilter>;
  description?: InputMaybe<StringFieldComparison>;
  idCustomer?: InputMaybe<IdFilterComparison>;
  idOrder?: InputMaybe<IdFilterComparison>;
  idServiceCustomer?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupportTicketFilter>>;
  order?: InputMaybe<SupportTicketFilterOrderFilter>;
  priority?: InputMaybe<StringFieldComparison>;
  ref?: InputMaybe<StringFieldComparison>;
  serviceCustomer?: InputMaybe<SupportTicketFilterUserFilter>;
  status?: InputMaybe<StringFieldComparison>;
  subject?: InputMaybe<StringFieldComparison>;
  suggestion?: InputMaybe<JsonFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SupportTicketFilterOrderFilter = {
  address_city?: InputMaybe<StringFieldComparison>;
  address_name?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SupportTicketFilterOrderFilter>>;
  cancelReason?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer_firstName?: InputMaybe<StringFieldComparison>;
  datetime?: InputMaybe<DateFieldComparison>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  deliveryMan_firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idAddress?: InputMaybe<IdFilterComparison>;
  idCarteVitale?: InputMaybe<IdFilterComparison>;
  idCustomer?: InputMaybe<IdFilterComparison>;
  idDeliveryMan?: InputMaybe<IdFilterComparison>;
  idMutuelle?: InputMaybe<IdFilterComparison>;
  idOrdonnance?: InputMaybe<IdFilterComparison>;
  idVendor?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupportTicketFilterOrderFilter>>;
  preparer?: InputMaybe<StringFieldComparison>;
  ref?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  totalPrice?: InputMaybe<FloatFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vendor_firstName?: InputMaybe<StringFieldComparison>;
  vendor_infoLegale_name?: InputMaybe<StringFieldComparison>;
};

export type SupportTicketFilterUserFilter = {
  and?: InputMaybe<Array<SupportTicketFilterUserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupportTicketFilterUserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SupportTicketHistory = {
  __typename?: 'SupportTicketHistory';
  action: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idSupportTicket: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SupportTicketHistoryConnection = {
  __typename?: 'SupportTicketHistoryConnection';
  nodes: Array<SupportTicketHistory>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SupportTicketHistoryFilter = {
  and?: InputMaybe<Array<SupportTicketHistoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idSupportTicket?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupportTicketHistoryFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SupportTicketHistorySort = {
  direction: SortDirection;
  field: SupportTicketHistorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupportTicketHistorySortFields {
  CreatedAt = 'createdAt',
  IdSupportTicket = 'idSupportTicket',
  UpdatedAt = 'updatedAt'
}

export type SupportTicketMessage = {
  __typename?: 'SupportTicketMessage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idSender: Scalars['ID']['output'];
  idSupportTicket: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  sender: User;
  status: Scalars['String']['output'];
  supportTicket: SupportTicket;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SupportTicketMessageConnection = {
  __typename?: 'SupportTicketMessageConnection';
  nodes: Array<SupportTicketMessage>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SupportTicketMessageFilter = {
  and?: InputMaybe<Array<SupportTicketMessageFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idSender?: InputMaybe<IdFilterComparison>;
  idSupportTicket?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupportTicketMessageFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SupportTicketMessageSort = {
  direction: SortDirection;
  field: SupportTicketMessageSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupportTicketMessageSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IdSender = 'idSender',
  IdSupportTicket = 'idSupportTicket',
  UpdatedAt = 'updatedAt'
}

export type SupportTicketMessageUpdateFilter = {
  and?: InputMaybe<Array<SupportTicketMessageUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  idSender?: InputMaybe<IdFilterComparison>;
  idSupportTicket?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupportTicketMessageUpdateFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SupportTicketSort = {
  direction: SortDirection;
  field: SupportTicketSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupportTicketSortFields {
  Category = 'category',
  CreatedAt = 'createdAt',
  Description = 'description',
  IdCustomer = 'idCustomer',
  IdOrder = 'idOrder',
  IdServiceCustomer = 'idServiceCustomer',
  Priority = 'priority',
  Ref = 'ref',
  Status = 'status',
  Subject = 'subject',
  Suggestion = 'suggestion',
  UpdatedAt = 'updatedAt'
}

export type Tarification = {
  __typename?: 'Tarification';
  createdAt: Scalars['DateTime']['output'];
  dateDebut: Scalars['DateTime']['output'];
  dateFin: Scalars['DateTime']['output'];
  deliveryManPercentage: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  prixKmJour: Scalars['Int']['output'];
  prixKmSoir: Scalars['Int']['output'];
  tarifMinimum: Scalars['Int']['output'];
  tva: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TarificationConnection = {
  __typename?: 'TarificationConnection';
  nodes: Array<Tarification>;
  pageInfo: OffsetPageInfo;
};

export type TarificationDeleteResponse = {
  __typename?: 'TarificationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  deliveryManPercentage?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  prixKmJour?: Maybe<Scalars['Int']['output']>;
  prixKmSoir?: Maybe<Scalars['Int']['output']>;
  tarifMinimum?: Maybe<Scalars['Int']['output']>;
  tva?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TarificationFilter = {
  and?: InputMaybe<Array<TarificationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  dateDebut?: InputMaybe<DateFieldComparison>;
  dateFin?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<TarificationFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type TarificationSort = {
  direction: SortDirection;
  field: TarificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TarificationSortFields {
  CreatedAt = 'createdAt',
  DateDebut = 'dateDebut',
  DateFin = 'dateFin',
  UpdatedAt = 'updatedAt'
}

export type Token = {
  __typename?: 'Token';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UpdateAddress = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBankAccount = {
  iban?: InputMaybe<Scalars['String']['input']>;
  payoutFrequency?: InputMaybe<Scalars['String']['input']>;
  swift?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFile = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInfoLegale = {
  name?: InputMaybe<Scalars['String']['input']>;
  numTva?: InputMaybe<Scalars['String']['input']>;
  sirenCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyFilesInput = {
  filter: FileUpdateFilter;
  update: UpdateFile;
};

export type UpdateManyNotificationsInput = {
  filter: NotificationUpdateFilter;
  update: UpdateNotification;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  updatedCount: Scalars['Int']['output'];
};

export type UpdateManySupportTicketMessagesInput = {
  filter: SupportTicketMessageUpdateFilter;
  update: UpdateSupportTicketMessage;
};

export type UpdateManyUsersInput = {
  filter: UserUpdateFilter;
  update: UpdateUser;
};

export type UpdateNotification = {
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneAddressInput = {
  id: Scalars['ID']['input'];
  update: UpdateAddress;
};

export type UpdateOneBankAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateBankAccount;
};

export type UpdateOneFileInput = {
  id: Scalars['ID']['input'];
  update: UpdateFile;
};

export type UpdateOneInfoLegaleInput = {
  id: Scalars['ID']['input'];
  update: UpdateInfoLegale;
};

export type UpdateOneNotificationInput = {
  id: Scalars['ID']['input'];
  update: UpdateNotification;
};

export type UpdateOneOpeningTimeInput = {
  id: Scalars['ID']['input'];
  update: UpdateOpeningTime;
};

export type UpdateOneOrderAssignmentInput = {
  id: Scalars['ID']['input'];
  update: UpdateOrderAssignment;
};

export type UpdateOneOrderInput = {
  id: Scalars['ID']['input'];
  update: UpdateOrder;
};

export type UpdateOneOrderItemInput = {
  id: Scalars['ID']['input'];
  update: UpdateOrderItem;
};

export type UpdateOneSupportTicketInput = {
  id: Scalars['ID']['input'];
  update: UpdateSupportTicket;
};

export type UpdateOneSupportTicketMessageInput = {
  id: Scalars['ID']['input'];
  update: UpdateSupportTicketMessage;
};

export type UpdateOneTarificationInput = {
  id: Scalars['ID']['input'];
  update: UpdateTarification;
};

export type UpdateOneUserInput = {
  id: Scalars['ID']['input'];
  update: UpdateUser;
};

export type UpdateOpeningTime = {
  days?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateOrder = {
  datetime?: InputMaybe<Scalars['DateTime']['input']>;
  idAddress?: InputMaybe<Scalars['ID']['input']>;
  idCarteVitale?: InputMaybe<Scalars['ID']['input']>;
  idCustomer?: InputMaybe<Scalars['ID']['input']>;
  idDeliveryMan?: InputMaybe<Scalars['ID']['input']>;
  idMutuelle?: InputMaybe<Scalars['ID']['input']>;
  idOrdonnance?: InputMaybe<Scalars['ID']['input']>;
  idVendor?: InputMaybe<Scalars['ID']['input']>;
  preparer?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderAssignment = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderItem = {
  description?: InputMaybe<Scalars['String']['input']>;
  libelle?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  refund?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSupportTicket = {
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  idOrder?: InputMaybe<Scalars['ID']['input']>;
  idServiceCustomer?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  suggestion?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateSupportTicketMessage = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTarification = {
  dateDebut?: InputMaybe<Scalars['DateTime']['input']>;
  dateFin?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryManPercentage?: InputMaybe<Scalars['Int']['input']>;
  prixKmJour?: InputMaybe<Scalars['Int']['input']>;
  prixKmSoir?: InputMaybe<Scalars['Int']['input']>;
  tarifMinimum?: InputMaybe<Scalars['Int']['input']>;
  tva?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUser = {
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryAvailability?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fcmToken?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  idRole?: InputMaybe<Scalars['ID']['input']>;
  idVehicle?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastPosition?: InputMaybe<Scalars['JSON']['input']>;
  optionalPhone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  addresses?: Maybe<Array<Address>>;
  admin?: Maybe<User>;
  bankAccount?: Maybe<BankAccount>;
  bankAccountFiles?: Maybe<Array<File>>;
  birthDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliveryAvailability?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  entity?: Maybe<Entity>;
  fcmToken?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idAdmin?: Maybe<Scalars['ID']['output']>;
  idEntity?: Maybe<Scalars['ID']['output']>;
  idRole: Scalars['ID']['output'];
  idVehicle?: Maybe<Scalars['ID']['output']>;
  infoLegale?: Maybe<InfoLegale>;
  isLimitedAccount: Scalars['Boolean']['output'];
  lastConnection?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  lastPasswordUpdate?: Maybe<Scalars['DateTime']['output']>;
  lastPosition?: Maybe<Scalars['JSON']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  optionalPhone?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phoneMobile: Scalars['String']['output'];
  profession?: Maybe<Scalars['String']['output']>;
  role: Role;
  signinAttempts: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  stepCreation: Scalars['Int']['output'];
  stripeAccount?: Maybe<StripeAccount>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userAgent?: Maybe<Scalars['String']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleFiles?: Maybe<Array<File>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryAvailability?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fcmToken?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idAdmin?: Maybe<Scalars['ID']['output']>;
  idEntity?: Maybe<Scalars['ID']['output']>;
  idRole?: Maybe<Scalars['ID']['output']>;
  idVehicle?: Maybe<Scalars['ID']['output']>;
  lastConnection?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastPasswordUpdate?: Maybe<Scalars['DateTime']['output']>;
  lastPosition?: Maybe<Scalars['JSON']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  optionalPhone?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phoneMobile?: Maybe<Scalars['String']['output']>;
  profession?: Maybe<Scalars['String']['output']>;
  signinAttempts?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stepCreation?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userAgent?: Maybe<Scalars['String']['output']>;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  bankAccount?: InputMaybe<UserFilterBankAccountFilter>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  entity?: InputMaybe<UserFilterEntityFilter>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  infoLegale?: InputMaybe<UserFilterInfoLegaleFilter>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  role?: InputMaybe<UserFilterRoleFilter>;
  stripeAccount?: InputMaybe<UserFilterStripeAccountFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vehicle?: InputMaybe<UserFilterVehicleFilter>;
};

export type UserFilterBankAccountFilter = {
  and?: InputMaybe<Array<UserFilterBankAccountFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  iban?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterBankAccountFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserFilterEntityFilter = {
  and?: InputMaybe<Array<UserFilterEntityFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<UserFilterEntityFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserFilterInfoLegaleFilter = {
  and?: InputMaybe<Array<UserFilterInfoLegaleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  numTva?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterInfoLegaleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserFilterRoleFilter = {
  and?: InputMaybe<Array<UserFilterRoleFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<UserFilterRoleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserFilterStripeAccountFilter = {
  account?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<UserFilterStripeAccountFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<UserFilterStripeAccountFilter>>;
  status?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserFilterVehicleFilter = {
  and?: InputMaybe<Array<UserFilterVehicleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterVehicleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserHistory = {
  __typename?: 'UserHistory';
  action: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserHistoryConnection = {
  __typename?: 'UserHistoryConnection';
  nodes: Array<UserHistory>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserHistoryFilter = {
  and?: InputMaybe<Array<UserHistoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  idUser?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserHistoryFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserHistorySort = {
  direction: SortDirection;
  field: UserHistorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserHistorySortFields {
  CreatedAt = 'createdAt',
  IdUser = 'idUser',
  UpdatedAt = 'updatedAt'
}

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  IdAdmin = 'idAdmin',
  IdEntity = 'idEntity',
  IdRole = 'idRole',
  IdVehicle = 'idVehicle',
  LastName = 'lastName',
  OptionalPhone = 'optionalPhone',
  PhoneMobile = 'phoneMobile',
  UpdatedAt = 'updatedAt'
}

export type UserUpdateFilter = {
  and?: InputMaybe<Array<UserUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  idAdmin?: InputMaybe<IdFilterComparison>;
  idEntity?: InputMaybe<IdFilterComparison>;
  idRole?: InputMaybe<IdFilterComparison>;
  idVehicle?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  optionalPhone?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserUpdateFilter>>;
  phoneMobile?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  files: Array<File>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  nodes: Array<Vehicle>;
  pageInfo: OffsetPageInfo;
};

export type VehicleFilter = {
  and?: InputMaybe<Array<VehicleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<VehicleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type VehicleSort = {
  direction: SortDirection;
  field: VehicleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VehicleSortFields {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}
