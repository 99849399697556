export const ORDER_STATUSES = {
  ALL_ORDERS: "ALL_ORDERS",
  NEW: "NEW",
  NOT_VERIFIED: "NOT_VERIFIED",
  UNPAID: "UNPAID",
  PAID: "PAID",
  ASSIGNED: "ASSIGNED",
  UNAVAILABLE: "UNAVAILABLE",
  REFUNDED: "REFUNDED",
  DELIVERED: "DELIVERED",
  READY: "READY",
  CANCELED: "CANCELED"
};

export const SUPPORT_TICKET_STATUSES = {
  OPENED: "OPENED",
  CLOSED: "CLOSED",
  RESOLVED: "RESOLVED",
  CANCELED: "CANCELED"
};

export const USER_TYPES = {
  ALL_USERS: "ALL_USERS",
  INDIVIDUAL: "INDIVIDUAL",
  PROFESSIONAL: "PROFESSIONAL",
  LIVREUR: "LIVREUR",
  PHARMACY: "PHARMACY",
  PREPARER: "PREPARER",
  SERVICE_CUSTOMER: "SERVICE_CUSTOMER"
};

export const FILE_STATUSES = {
  NOT_VERIFIED: "NOT_VERIFIED",
  INVALID: "INVALID",
  VALID: "VALID"
};

export const FILE_NAMES = {
  ASSURANCE: "ASSURANCE",
  CARTE_GRISE: "CARTE_GRISE",
  VISITE_TECHNIQUE: "VISITE_TECHNIQUE",
  MUTUELLE: "MUTUELLE",
  CARTE_VITALE: "CARTE_VITALE",
  ORDONNANCE: "ORDONNANCE",
  RIB: "RIB",
  KBIS: "KBIS",
  CONTRAT: "CONTRAT"
};

export const NOTIFICATION_IS_DONE_STATUSES = {
  PENDING: "PENDING",
  DONE: "DONE"
};

