import { User } from "@graphql/*";
import moment from "moment";
import { DEFAULT_PAGING_LIMIT } from "./constants";

export const postSigninNavigation = (user: User) => {
  const beginningOfMonth = moment().startOf("M").format("YYYY/MM/DD");
  const endOfMonth = moment().endOf("M").format("YYYY/MM/DD");
  const entity = user.entity;
  if (!entity) {
    if (user.role.code === "SERVICE_CUSTOMER") {
      return "/dashboard/my-tickets?status=ALL_TICKETS";
    } else {
      return "/dashboard/superadmin-dashboard?status=SUPERADMIN_DASHBOARD&dateRange=week";
    }
  }
  else {
    switch (entity.code) {
      case "INDIVIDUAL":
      case "PROFESSIONAL":
        return `/dashboard/my-orders?status=NOT_VERIFIED&limit=${DEFAULT_PAGING_LIMIT}&offset=0&from=${beginningOfMonth}&to=${endOfMonth}&collapsed=ALL_ORDERS`;
      case "PHARMACY":
        switch (user.role.code) {
          case "ADMIN":
            return `/dashboard/pharmacy-dashboard?status=PHARMACY_DASHBOARD&dateRange=week`;
          case "USER":
            return `/dashboard/my-orders?status=NOT_VERIFIED&limit=${DEFAULT_PAGING_LIMIT}&offset=0&from=${beginningOfMonth}&to=${endOfMonth}&collapsed=ALL_ORDERS`;
        }
        break;
      case "LIVREUR":
        return `/dashboard/livreur-dashboard?status=LIVREUR_DASHBOARD&dateRange=week`;
    }
  }
};
