import React, { FC, useState } from 'react';
import { Modal } from '@components/Modal';
import { Box, TextField , Autocomplete} from '@mui/material';
import { useStyles } from './styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { UserFilter, useUsersQuery , InputMaybe} from '@graphql/';
import { useApplicationContext } from '@src/context';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';



interface UserSelectProps {
  open: boolean;
  setopen: (value: React.SetStateAction<boolean>) => void;
  onSelect: (idUser: string) => void;
  idSelected?: string | null;
  title: string
  filter?: InputMaybe<UserFilter>
}

const UserSelect: FC<UserSelectProps> = ({ open, setopen , onSelect, idSelected , title , filter}) => {


  const handleclose = () => {
    setopen(false);
  };

  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useUsersQuery({ variables: { filter } });
  const users = data?.users?.nodes || [];
  const {connectedUser} = useApplicationContext()
  const otherUsers = users.filter((user) => user.id != connectedUser?.id)
    
  return (
    <>
      <Modal
        open={open}
        onClose={handleclose}
        title={title}
        children={
          <Box className={classes.root}>
            <Typography variant='subtitle2'>
              {t("supportTicket.form.assignation.subtitle")} 
            </Typography>
            <Autocomplete
              filterOptions={(options, state) => {
                return [{...connectedUser, firstName: t("commons.me"), lastName: ""}, ...options.filter(option => `${option.firstName} ${option.lastName}`.startsWith(state.inputValue))]
              }}
              onChange={(event , value)=>{
                if(value){
                  onSelect(value.id)
                }                
              }}
              options={otherUsers}
              getOptionLabel={(user) => `${user.firstName} ${user.lastName}` }
              renderOption={(props , option) => (
                <Box component="li" {...props} className={classes.boxOption}>             
                    {`${option.firstName} ${option.lastName}`}
                    {(option.id === idSelected)? <DoneOutlineIcon sx={{color: "#2e7d32" , }}/> : null}
                </Box>
              )
              
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("commons.user")}
                  variant="standard"
                  fullWidth
                  margin="dense"
                  autoFocus
                  required
                />
              )}
            />
          </Box>
        }
      />
    </>
  );
}

export default UserSelect;
