import * as Types from '../../types';

import { gql } from '@apollo/client';
export type NotificationInfoFragment = { __typename?: 'Notification', id: string, idUser: string, status: string, category: string, action: string, isDone?: boolean | null, type?: string | null, data: any, createdAt: any, updatedAt?: any | null };

export const NotificationInfoFragmentDoc = gql`
    fragment NotificationInfo on Notification {
  id
  idUser
  status
  category
  action
  isDone
  type
  data
  createdAt
  updatedAt
}
    `;