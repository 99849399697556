import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column } from "@components/Table";
import { Order, User } from "@graphql/";
import { useParams } from "@src/hooks";
import { encodeParam } from "@utils/encodeParams";
import { FILE_STATUSES, ORDER_STATUSES } from "@utils/constants";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";


export const usePharmacyProps = ({
  setOrder,
  setShowFileViewer,
  setShowOrderItemsModal,
  entity,
  connectedUser,
  setShowOrderDetails,
  setOpenUserSelectModal
}: {
  setShowOrderItemsModal: Dispatch<SetStateAction<boolean>>;
  setShowFileViewer: Dispatch<SetStateAction<boolean>>;
  entity?: { code: string } | null;
  setOrder: Dispatch<SetStateAction<Order | undefined>>;
  connectedUser?: User;
  setShowOrderDetails: Dispatch<SetStateAction<boolean>>;
  setOpenUserSelectModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { updateOneOrder } = useOrderMutations();
  const { requiredParams } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const redirect = (status: string) => {
    const query = encodeParam({
      ...requiredParams,
      status,
      offset: 0,
    });
    navigate(`${pathname}?${query}`);
  };

  const pharmacyActions = (row: Order) => {
    const handleSelect = (selected: string) => {
      setOrder(row);
      switch (selected) {
        case "ASSIGN":
          setOpenUserSelectModal(true)
          break;
        case "FILES":
          setShowFileViewer(true);
          break;
        case "ORDER_ITEM":
          setShowOrderItemsModal(true);
          break;
        case ORDER_STATUSES.UNAVAILABLE:
        case ORDER_STATUSES.UNPAID:
        case ORDER_STATUSES.READY:
          updateOneOrder({
            variables: {
              input: { id: row.id, update: { status: selected } },
            },
          }).then(() => {
            redirect(selected);
          });
          break;
        case "DETAILS":
          setShowOrderDetails(true);
          break;
      }
    };
    const menus = [
      {
        label: t("commons.assign"),
        value: "ASSIGN",
        disabled: row.preparer,
      },
      {
        label: t("order.list.action.orderDocument"),
        value: "FILES",
      },
      {
        label: t("order.list.action.orderItems"),
        value: "ORDER_ITEM",
        disabled: row.files?.some((file) => file.status !== FILE_STATUSES.VALID),
      },
      {
        label: t("order.list.action.orderUnavailable"),
        value: ORDER_STATUSES.UNAVAILABLE,
        disabled: row.status !== ORDER_STATUSES.NOT_VERIFIED,
      },
      {
        label: t("order.list.action.orderPendingPayment"),
        value: ORDER_STATUSES.UNPAID,
        disabled:
          row.status !== ORDER_STATUSES.NOT_VERIFIED || !row.orderItems?.length,
      },
      {
        label: t("order.list.action.readyForDelivery"),
        value: ORDER_STATUSES.READY,
        disabled: row.status !== ORDER_STATUSES.PAID,
      },
      {
        label: t("commons.seeDetails"),
        value: "DETAILS",
      },
    ];
    return <MoreHorizMenu items={menus} onSelect={handleSelect} />;
  };

  const pharmacyColumns: Column[] =
    entity?.code === "PHARMACY"
      ? [
          {
            label: t("order.list.label.preparer"),
            name: "preparer",
            breaking: true,
          },
          {
            label: t("order.list.label.totalPrice"),
            name: "vendorPrice",
            sortable: true,
            renderer: (row: Order) => {
              return `${Number(row.vendorPrice).toFixed(2)}€`;
            },
          },
        ]
      : [];
  return { pharmacyColumns, pharmacyActions };
};
