import { Modal } from "@components/Modal";
import { SupportTicket } from "@graphql/";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useSupportTicketsMutations } from "@src/hooks";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface SuggestionFormProps {
  open: boolean;
  onClose: () => void;
  supportTicket: SupportTicket;
}

export const SuggestionForm: FC<SuggestionFormProps> = ({
  open,
  onClose,
  supportTicket,
}) => {
  const { t } = useTranslation();
  const [suggestion, setSuggestion] = useState<{
    suggestion:
      | "PRODUCT_REPLACEMENT"
      | "PARTIAL_REFUND"
      | "TOTAL_REFUND"
      | "DISCOUNT";
    comment: string;
  }>({
    suggestion: "PRODUCT_REPLACEMENT",
    comment: "",
  });

  const { updateOneSupportTicket } = useSupportTicketsMutations();

  const refundReasons = [
    {
      label: "Remplacement du produit",
      value: "PRODUCT_REPLACEMENT",
    },
    {
      label: "Remboursement partiel",
      value: "PARTIAL_REFUND",
    },
    {
      label: "Remboursement total",
      value: "TOTAL_REFUND",
    },
    {
      label: "Bon de réduction",
      value: "DISCOUNT",
    },
  ];

  const handleRefundReasonChange = (
    e: SelectChangeEvent<
      "PRODUCT_REPLACEMENT" | "PARTIAL_REFUND" | "TOTAL_REFUND" | "DISCOUNT"
    >
  ) => {
    setSuggestion((prev) => ({
      ...prev,
      suggestion: e.target.value as
        | "PRODUCT_REPLACEMENT"
        | "PARTIAL_REFUND"
        | "TOTAL_REFUND"
        | "DISCOUNT",
    }));
  };

  const handleSubmit = () => {
    updateOneSupportTicket({
      variables: {
        input: { id: supportTicket.id, update: { suggestion: suggestion } },
      },
    }).then(() => onClose());
  };

  return (
    <Modal
      title={`Proposition de solution pour le ticket N° ${supportTicket.ref}`}
      open={open}
      maxWidth="md"
      onClose={onClose}
      actions={
        <Button variant="contained" onClick={handleSubmit}>
          Proposer la solution
        </Button>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {supportTicket.suggestion && (
          <Box marginBottom={2}>
            <Typography fontWeight="bold">Solution déja propposé:</Typography>
            <Typography>
              {
                refundReasons.find(
                  (reason) =>
                    reason.value === supportTicket.suggestion.suggestion
                )?.label
              }{" "}
              {supportTicket.suggestion.comment
                ? `(${supportTicket.suggestion.comment})`
                : ""}
            </Typography>
          </Box>
        )}
        <Select
          value={suggestion.suggestion}
          onChange={handleRefundReasonChange}
          sx={{ marginBottom: 2 }}
        >
          {refundReasons.map((option) => (
            <MenuItem
              key={`select-category-${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <TextareaAutosize
          onChange={(e) =>
            setSuggestion((prev) => ({
              ...prev,
              comment: e.target.value,
            }))
          }
          placeholder="Commentaire"
          minRows={4}
          value={suggestion.comment}
        />
      </Box>
    </Modal>
  );
};
