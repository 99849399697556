import { SUPPORT_TICKET_STATUSES } from "@utils/constants";
import { useTranslation } from "react-i18next";
import React from "react"

export const useSupportTicketStatuses = () => {
  const { t } = useTranslation();
  const supportTicketStatuses = [
    {
      value: SUPPORT_TICKET_STATUSES.OPENED,
      status: SUPPORT_TICKET_STATUSES.OPENED,
      label: t("supportTicket.list.statuses.OPENED"),
      color: "#1246B8",
    },
    {
      value: SUPPORT_TICKET_STATUSES.CLOSED,
      status: SUPPORT_TICKET_STATUSES.CLOSED,
      label: t("supportTicket.list.statuses.CLOSED"),
      color: "#D32F2F",
    },
    {
      value: SUPPORT_TICKET_STATUSES.RESOLVED,
      status: SUPPORT_TICKET_STATUSES.RESOLVED,
      label: t("supportTicket.list.statuses.RESOLVED"),
      color: "#31956B",
    },
    {
      value: SUPPORT_TICKET_STATUSES.CANCELED,
      status: SUPPORT_TICKET_STATUSES.CANCELED,
      label: t("supportTicket.list.statuses.CANCELED"),
      color: "#636363",
    },
  ];

  const getSupportTicketByStatus = (status: string) => {
    const foundStatus = supportTicketStatuses.find((e) => e.status === status);
    if (!foundStatus) throw "supportTicket status not found";
    return foundStatus;
  };
  return { getSupportTicketByStatus, supportTicketStatuses };
};
