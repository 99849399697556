import {
  Addchart,
  ListAltOutlined,
  ManageHistoryOutlined,
  PendingActionsOutlined,
  PermContactCalendarOutlined,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import { supportTicketStatuses } from "@utils/constants";
import { useTranslation } from "react-i18next";
import React from "react";
import { useApplicationContext } from "@src/context";

export const useServiceCustomer = (supportTicketsCounts: any) => {
  const { t } = useTranslation();
  const { connectedUser } = useApplicationContext();
  const serviceCustomerItems =
    connectedUser?.role?.code === "SERVICE_CUSTOMER"
      ? [
          {
            name: "SERVICE_CUSTOMER_DASHBOARD",
            pathname: `/dashboard/service-customer-dashboard?status=SERVICE_CUSTOMER_DASHBOARD&dateRange=week`,
            label: "Dashboard",
            count: 0,
            icon: <Addchart />,
          },
          {
            name: "ALL_TICKETS",
            pathname: "/dashboard/my-tickets?status=ALL_TICKETS&collapsed=ALL_TICKETS",
            label: t("sideNav.myTickets"),
            count: supportTicketsCounts?.ALL,
            icon: <ListAltOutlined />,
            subItems: supportTicketStatuses.map((supportTicketStatus) => {
              const { status, icon } = supportTicketStatus;
              return {
                name: status,
                pathname: `/dashboard/my-tickets?status=${status}&collapsed=ALL_TICKETS`,
                label: t(`sideNav.serviceCustomer.supportTicket.${status}`),
                count: supportTicketsCounts && supportTicketsCounts[status],
                icon: <Icon component={icon} />,
              };
            }),
          },
          {
            name: "NOT_ASSIGNED",
            pathname: "/dashboard/my-tickets?status=NOT_ASSIGNED&collapsed=ALL_TICKETS",
            label: t("sideNav.serviceCustomer.supportTicket.NEW"),
            count: supportTicketsCounts?.NOT_ASSIGNED,
            icon: <PendingActionsOutlined />,
          },
          {
            name: "",
            pathname: "/dashboard/customers",
            label: t("sideNav.serviceCustomer.CUSTOMER"),
            count: supportTicketsCounts?.CLIENTS,
            icon: <PermContactCalendarOutlined />,
          },
          {
            name: "",
            pathname: "/dashboard/unassigned-orders",
            label: t("sideNav.serviceCustomer.UNASSIGNED_ORDERS"),
            count: supportTicketsCounts?.UNASSIGNED_ORDERS,
            icon: <ManageHistoryOutlined />,
          },
        ]
      : [];
  return { serviceCustomerItems };
};
