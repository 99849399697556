import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FeedbackInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_FeedbackMutationVariables = Types.Exact<{
  input: Types.CreateOneFeedbackInput;
}>;


export type Create_One_FeedbackMutation = { __typename?: 'Mutation', createOneFeedback: { __typename?: 'Feedback', id: string, category: string, orderRef?: string | null, score: number, comment?: string | null } };


export const Create_One_FeedbackDocument = gql`
    mutation CREATE_ONE_FEEDBACK($input: CreateOneFeedbackInput!) {
  createOneFeedback(input: $input) {
    ...FeedbackInfo
  }
}
    ${FeedbackInfoFragmentDoc}`;
export type Create_One_FeedbackMutationFn = Apollo.MutationFunction<Create_One_FeedbackMutation, Create_One_FeedbackMutationVariables>;

/**
 * __useCreate_One_FeedbackMutation__
 *
 * To run a mutation, you first call `useCreate_One_FeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_FeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneFeedbackMutation, { data, loading, error }] = useCreate_One_FeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_FeedbackMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_FeedbackMutation, Create_One_FeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_FeedbackMutation, Create_One_FeedbackMutationVariables>(Create_One_FeedbackDocument, options);
      }
export type Create_One_FeedbackMutationHookResult = ReturnType<typeof useCreate_One_FeedbackMutation>;
export type Create_One_FeedbackMutationResult = Apollo.MutationResult<Create_One_FeedbackMutation>;
export type Create_One_FeedbackMutationOptions = Apollo.BaseMutationOptions<Create_One_FeedbackMutation, Create_One_FeedbackMutationVariables>;