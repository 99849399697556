import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useStripeCheckoutSessionMutations } from "@src/hooks";
import { DEFAULT_PAGING_LIMIT, ORDER_STATUSES } from "@utils/constants";
import moment from "moment";
import React, { FC, useEffect, MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const CheckoutSession: FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const idSession = query.get("idSession");
  const navigate = useNavigate();
  const beginningOfMonth = moment().startOf("M").format("YYYY/MM/DD");
  const endOfMonth = moment().endOf("M").format("YYYY/MM/DD");
  const { updateOneOrder } = useOrderMutations();
  const { retrieveCheckoutSession, retrievingCheckoutSession } =
    useStripeCheckoutSessionMutations();
  useEffect(() => {
    if (idSession && !retrievingCheckoutSession.called) {
      retrieveCheckoutSession({ variables: { idSession } }).then((response) => {
        if (response.data?.retrieveCheckoutSession.status === "complete") {
          updateOneOrder({
            variables: {
              input: {
                id: response.data?.retrieveCheckoutSession.order.id,
                update: { status: ORDER_STATUSES.PAID },
              },
            },
          });
        }
      });
    }
  }, []);

  const handleGoToMyOrders = (e: MouseEvent<HTMLButtonElement>) => {
    navigate(`/dashboard/my-orders?status=NOT_VERIFIED&limit=${DEFAULT_PAGING_LIMIT}&offset=0&from=${beginningOfMonth}&to=${endOfMonth}`);
  };

  const order = retrievingCheckoutSession.data?.retrieveCheckoutSession.order;
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {retrievingCheckoutSession.loading ? (
        <CircularProgress />
      ) : retrievingCheckoutSession.data?.retrieveCheckoutSession?.status ===
        "complete" ? (
        <Typography>{`Votre commande n° ${order?.ref} a bien été payé. DelivDose vous remercie`}</Typography>
      ) : (
        <Typography>{`Il y a eu une erreur lors du paiement de votre commande n° ${order?.ref}`}</Typography>
      )}
      {!retrievingCheckoutSession.loading && (
        <Button variant="outlined" onClick={handleGoToMyOrders}>
          Voir mes commandes
        </Button>
      )}
    </Box>
  );
};
