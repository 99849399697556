import { useTranslation } from "react-i18next";
import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { Column, Table } from "@components/Table";
import {
  Order,
  OrderItem,
  useOrder_HistoriesQuery,
  useOrder_ItemsQuery,
} from "@graphql/";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import moment from "moment";
import React, { FC, useState } from "react";
import { useStyles } from "./styles";
import { Modal } from "@components/Modal";
import { OrderItemMobileRow } from "@components/MobileTableRows/OrderItemMobileRow";
import { ORDER_STATUSES } from "@utils/constants";

interface OrderDetailsProps {
  show: boolean;
  onClose: () => void;
  order?: Order;
}

const OrderDetails: FC<OrderDetailsProps> = ({ order, show, onClose }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const orderHistoriesQuery = useOrder_HistoriesQuery({
    fetchPolicy: "network-only",
    variables: { filter: { idOrder: { eq: order?.id } } },
  });
  const [term, setTerm] = useState("");
  const orderItemsQuery = useOrder_ItemsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        or:
          term !== ""
            ? [
                {
                  description: {
                    iLike: `%${term}%`,
                  },
                  libelle: { iLike: `%${term}%` },
                },
              ]
            : undefined,
        idOrder: { eq: order?.id },
      },
    },
    skip: !order,
  });

  const orderItems = orderItemsQuery.data?.orderItems.nodes || [];
  const { updateOneOrder } = useOrderMutations();
  const orderHistories = orderHistoriesQuery.data?.orderHistories.nodes || [];

  const columns: Column[] = [
    {
      label: t("order.list.details.label"),
      name: "libelle",
    },
    {
      label: t("order.list.details.description"),
      name: "description",
    },
    {
      label: t("order.list.details.quantity"),
      name: "quantity",
    },
    {
      label: t("order.list.details.pu"),
      name: "unitPrice",
      renderer: (row: OrderItem) => {
        return `${row.unitPrice}€`;
      },
    },
    {
      label: t("order.list.details.totalPrice"),
      name: "totalPrice",
      renderer: (row: OrderItem) => {
        return `${row.totalPrice}€`;
      },
    },
    {
      label: t("order.list.details.refund"),
      name: "refund",
      renderer: (row: OrderItem) => {
        return `${row.refund}€`;
      },
    },
  ];

  const handleAcceptProposal = () => {
    if (order) {
      updateOneOrder({
        variables: { input: { id: order?.id, update: { status: ORDER_STATUSES.UNPAID } } },
      }).then(() => onClose());
    }
  };

  const handleSearch = (term: string) => {
    setTerm(term);
  };
  return (
    <Modal
      open={show}
      maxWidth="lg"
      title={`${t("order.list.details.title")} ${order?.ref}`}
      onClose={onClose}
    >
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={
            order?.status === ORDER_STATUSES.UNAVAILABLE
              ? t("order.list.details.orderItemsOfferedByThePharmacy") 
              : t("order.list.details.items") 
          }
        />
        <CardContent>
          <Table
            columns={columns}
            data={orderItems}
            toolbar={
              order?.status === ORDER_STATUSES.UNAVAILABLE && order.orderItems?.length ? (
                <Grid container spacing={2} justifyContent="end">
                  <Grid item xs={12} lg={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAcceptProposal}
                      className={classes.createBtn}
                      fullWidth
                    >
                      {
                        t("order.list.details.acceptTheProposal")
                      }
                    </Button>
                  </Grid>
                </Grid>
              ) : undefined
            }
            title={t("order.list.details.orderItemsList")}
            model="orderItem"
            mobileTableRow={<OrderItemMobileRow />}
            term={term}
            onSearch={handleSearch}
            loading={orderItemsQuery.loading}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={t("order.list.details.historical")} />
        <CardContent>
          <Timeline>
            {orderHistories.map((orderHistory, index) => (
              <TimelineItem key={`timeline-item-${index}`}>
                <TimelineOppositeContent color="textSecondary">
                  {moment(orderHistory.createdAt).format("DD/MM/YYYY à HH:mm")}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{orderHistory.action}</TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Modal>
  );
};
export { OrderDetails };
