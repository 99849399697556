import { COLORS } from "@utils/colors";
import { NOTIFICATION_IS_DONE_STATUSES } from "@utils/constants";
import { useTranslation } from "react-i18next";

export const useNotificationIsDoneStatuses = () => {
  const { t } = useTranslation();
  const notificationIsDoneStatuses = [
    {
      label: t("notification.isDoneStatuses.DONE"),
      value: true,
      color: COLORS.GREEN,
    },
    {
      label: t("notification.isDoneStatuses.PENDING"),
      value: false,
      color: COLORS.RED,
    },
  ];
  return { notificationIsDoneStatuses };
};
