import { useApolloClient } from "@apollo/client";
import {
  useCreate_One_OrderMutation,
  useUpdate_One_OrderMutation,
  useDelete_One_OrderMutation,
  useVerify_Delivery_CodeMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { ORDER_STATUSES } from "@utils/generatedConstants";
export const useOrderMutations = () => {
  const client = useApolloClient();
  const { notify } = useApplicationContext();
  const [createOneOrder, creatingOneOrder] = useCreate_One_OrderMutation({
    onCompleted: () => {
      client.refetchQueries({
        include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
      });
      notify({
        message: "La commande a ajouté avec succès",
        type: "success",
      });
    },
    onError: () => {
      
      notify({
        message:
          "Une erreur s'est produite lors de l'enrégistrement de votre commande",
        type: "error",
      });
    },
  });

  const [updateOneOrder, updatingOneOrder] = useUpdate_One_OrderMutation({
    onCompleted: () => {
      client.refetchQueries({
        include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
      });
      notify({
        message: "La commande a été mis à jour avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors de la mis à jour de la commande",
        type: "error",
      });
    },
  });

  const [deleteOneOrder, deletingOneOrder] = useDelete_One_OrderMutation({
    refetchQueries: ["ORDERS"],
    onCompleted: (data) => {
      client.refetchQueries({
        include: ["ORDERS", "ORDER_STATUSES_COUNTS", "VERIFY_BEARER_TOKEN"],
      });
      notify({
        message: "La commande a été supprimé avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors de la suppression de la commande",
        type: "error",
      });
    },
  });

  const [verifyDeliveryCode, verifyingDeliveryCode] =
    useVerify_Delivery_CodeMutation({
      refetchQueries: ["ORDERS"],
      onCompleted: (data) => {
        const order = data.verifyDeliveryCode;
        if (order) {
          notify({
            message: "Votre code de livraison est valide",
            type: "success",
          });
          updateOneOrder({
            variables: {
              input: { id: order.id, update: { status: ORDER_STATUSES.DELIVERED } },
            },
          });
        }
      },
      onError: () => {
        notify({
          message: "Le code de livraison est invalide",
          type: "error",
        });
      },
    });

  const loading =
    creatingOneOrder.loading ||
    updatingOneOrder.loading ||
    deletingOneOrder.loading;

  return {
    createOneOrder,
    updateOneOrder,
    deleteOneOrder,
    verifyDeliveryCode,
    verifyingDeliveryCode,
    loading,
    deletingOneOrder,
  };
};
