import { PdfDownloaderBtn } from "@components/PdfDownloaderBtn";
import { StatsCard } from "@components/StatsCardNew";
import { ToggleButtons } from "@components/ToggleButtons";
import {
  useService_Customer_Dashboard_Periodic_StatsQuery
} from "@graphql/";
import {
  Block,
  DoneAll,
  FolderOff,
  FolderOpen,
  Timer
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { useApplicationContext } from "@src/context";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

export const ServiceCustomerDashboard: FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { search, pathname } = useLocation();
  const { connectedUser } = useApplicationContext();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const dateRange =
    (query.get("dateRange") as "week" | "month" | "year") || "week";
  const periodicStatsQuery = useService_Customer_Dashboard_Periodic_StatsQuery({
    fetchPolicy: "cache-and-network",
    variables: { idServiceCustomer: connectedUser?.id, period: dateRange },
    skip: !connectedUser?.id,
  });
  const periodicStats =
    periodicStatsQuery.data?.serviceCustomerDashboardPeriodicStats || {};

  const toggleButtons = [
    {
      label: t("dashboard.filter.week"),
      value: "week",
    },
    { label: t("dashboard.filter.month"), value: "month" },
    { label: t("dashboard.filter.year"), value: "year" },
  ];

  const handleDateRangeChange = (value: string) => {
    navigate(`${pathname}?status=PHARMACY_DASHBOARD&dateRange=${value}`);
  };

  return (
    <Box className={classes.root} id="ervice-customer-dashboard">
      <Box className={classes.filterRoot}>
        <PdfDownloaderBtn
          rootElementId="ervice-customer-dashboard"
          fileName={`service-customer-dashboard-${moment().format("x")}.pdf`}
          ignoredElementIds={["download-pdf-btn"]}
        />
        <ToggleButtons
          onChange={handleDateRangeChange}
          value={dateRange}
          toggleButtons={toggleButtons}
        />
      </Box>
      <Box sx={{ my: 3 }}>
        <Typography fontWeight="bold" variant="subtitle1">
          Statistiques
        </Typography>
        <Divider />
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={4} lg={2}>
              <StatsCard
                label="Ouverts"
                value={periodicStats?.globalStats?.OPENED || 0}
                icon={<FolderOpen fontSize="large" />}
                color="linear-gradient(45deg, #4099ff, #73b4ff)"
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <StatsCard
                label="Fermés"
                value={periodicStats?.globalStats?.CLOSED || 0}
                icon={<FolderOff fontSize="large" />}
                color="linear-gradient(45deg, #FFB64D, #ffcb80)"
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <StatsCard
                label="Annulés"
                value={periodicStats?.globalStats?.CANCELED || 0}
                icon={<Block fontSize="large" />}
                color="linear-gradient(45deg, #FF5370, #ff869a)"
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <StatsCard
                label="Résolus"
                value={periodicStats?.globalStats?.RESOLVED || 0}
                icon={<DoneAll fontSize="large" />}
                color="linear-gradient(45deg, #2ed8b6, #59e0c5)"
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <StatsCard
                label="Temps moyen"
                value={periodicStats?.globalStats?.AVG || 0}
                icon={<Timer fontSize="large" />}
                color="linear-gradient(45deg, #717171, #717171)"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
