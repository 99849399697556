import { ChipSelect } from "@components/ChipSelect";
import { FileViewer } from "@components/FileViewer";
import { UserMobileRow } from "@components/MobileTableRows/UserMobileRow";
import { Modal } from "@components/Modal";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column, Table } from "@components/Table";
import { UserForm } from "@components/UserForm";
import { User, useOrdersLazyQuery, useUsersQuery } from "@graphql/";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  useFilesMutations,
  usePagination,
  useParams,
  useUsersMutations,
  useUsersTermFilter,
} from "@src/hooks";
import moment from "moment";
import React, { FC, useState } from "react";
import { encodeParam } from "@utils/encodeParams";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmDeleteModal } from "@components/ConfirmDeleteModal";
import { CheckCircle } from "@mui/icons-material";
import { ORDER_STATUSES } from "@utils/constants";
export const Users: FC = () => {
  const pagination = usePagination();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { params } = useParams();
  const userType = params.status || "ALL_USERS";
  const withEntityTypes = [
    "INDIVIDUAL",
    "PROFESSIONAL",
    "LIVREUR",
    "PHARMACY",
  ];
  const { getUserTermFilter } = useUsersTermFilter(params);
  const navigate = useNavigate();
  const [getOrders, gettingOrders] = useOrdersLazyQuery();

  const usersQuery = useUsersQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        role: withEntityTypes.includes(userType)
          ? undefined
          : {
              code: {
                neq: userType === "SERVICE_CUSTOMER" ? undefined : "SUPERADMIN",
                eq:
                  userType === "SERVICE_CUSTOMER"
                    ? "SERVICE_CUSTOMER"
                    : undefined,
              },
            },
        entity: withEntityTypes.includes(userType)
          ? {
              code: {
                eq: userType,
              },
            }
          : undefined,
        idAdmin:
          userType === "PREPARER"
            ? {
                isNot: null,
              }
            : {
                eq: null,
              },
        ...getUserTermFilter(),
      },
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      pagination.setTotalCount(data.users.totalCount);
      if (user) {
        const newUser = data.users.nodes.find((u) => u.id === user.id);
        setUser(newUser as User);
      }
    },
  });
  const users = usersQuery.data?.users.nodes || [];
  const { updateUser, deleteUser, deletingUser } = useUsersMutations();
  const { updateFile, updatingFile } = useFilesMutations();
  const [showFileViewer, setShowFileViewer] = useState<boolean>(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [filesToShow, setFilesToShow] = useState<"BANK_ACCOUNT" | "VEHICLE">(
    "BANK_ACCOUNT"
  );
  const userStatuses = [
    {
      value: "EMAIL_VALIDATION",
      label: t("user.statuses.EMAIL_VALIDATION"),
      color: "#D32F2F",
    },
    {
      value: "EMAIL_VERIFIED",
      label: t("user.statuses.EMAIL_VERIFIED"),
      color: "#31956B",
    },
    {
      value: "SUSPENDED",
      label: t("user.statuses.SUSPENDED"),
      color: "#1246B8",
    },
    {
      value: "CHANGE_PASSWORD",
      label: t("user.statuses.CHANGE_PASSWORD"),
      color: "#636363",
    },
    {
      value: "MAX_SIGNIN_ATTEMPTS",
      label: t("user.statuses.MAX_SIGNIN_ATTEMPTS"),
      color: "#1246B8",
    },
    {
      value: "BEING_DELETED",
      label: t("user.statuses.BEING_DELETED"),
      color: "#1246B8",
    },
  ];
  const handleUpdateUserStatus = (value: string, id: string) => {
    updateUser({ variables: { input: { id, update: { status: value } } } });
  };

  const handleUpdateFileStatus = (e: SelectChangeEvent<string>) => {
    const { name: id, value } = e.target;
    setUser((prev) => {
      if (!prev) {
        return undefined;
      }
      return {
        ...prev,
        bankAccountFiles:
          filesToShow === "BANK_ACCOUNT"
            ? prev.bankAccountFiles?.map((file) =>
                file.id === id ? { ...file, status: value } : file
              )
            : prev.bankAccount,
        vehicleFiles:
          filesToShow === "VEHICLE"
            ? prev.vehicleFiles?.map((file) =>
                file.id === id ? { ...file, status: value } : file
              )
            : prev.vehicleFiles,
      };
    });
  };

  const handleSubmitFiles = async () => {
    if (user?.bankAccountFiles?.length) {
      await Promise.all(
        user.bankAccountFiles.map((file) => {
          updateFile({
            variables: {
              input: { id: file.id, update: { status: file.status } },
            },
          });
        })
      );
    }
    if (user?.vehicleFiles?.length) {
      await Promise.all(
        user.vehicleFiles.map((file) => {
          updateFile({
            variables: {
              input: { id: file.id, update: { status: file.status } },
            },
          });
        })
      );
    }
    setShowFileViewer(false);
  };

  const files =
    filesToShow === "BANK_ACCOUNT"
      ? user?.bankAccountFiles
      : user?.vehicleFiles;

  const moreHorizMenus = {
    PHARMACY: [
      {
        label: t("users.bankDocument"),
        value: "BANK_ACCOUNT",
      },
    ],
    LIVREUR: [
      {
        label: t("user.list.vehicleDocument"),
        value: "VEHICLE",
      },
      {
        label: t("user.list.bankDocument"),
        value: "BANK_ACCOUNT",
      },
    ],
    OTHER: [],
  };

  const actions = (row: User) => {
    const handleSelect = async (selected: string) => {
      setUser({
        ...row,
        birthDate: moment(row.birthDate).format("YYYY-MM-DD"),
      });
      switch (selected) {
        case "VEHICLE":
        case "BANK_ACCOUNT":
          setFilesToShow(selected as "VEHICLE" | "BANK_ACCOUNT");
          setShowFileViewer(true);
          break;
        case "UPDATE":
          setShowForm(true);
        case "DELETE":
          await getOrders({
            variables: {
              filter: {
                or: [
                  {
                    idCustomer: { eq: row.id },
                  },
                  {
                    idVendor: { eq: row.id },
                  },
                ],
                status: {
                  in: [
                    ORDER_STATUSES.NEW,
                    ORDER_STATUSES.NOT_VERIFIED,
                    ORDER_STATUSES.UNPAID,
                    ORDER_STATUSES.PAID,
                    ORDER_STATUSES.READY,
                  ],
                },
              },
            },
          });
          setShowConfirmDeleteDialog(true);
      }
    };
    const menus = [
      {
        label: t("commons.edit"),
        value: "UPDATE",
      },
      {
        label: t("commons.delete"),
        value: "DELETE",
      },
      ...(moreHorizMenus[
        (row.entity &&
          !row.idAdmin &&
          (row.entity.code as keyof typeof moreHorizMenus)) ||
          "OTHER"
      ] || []),
    ];
    return (
      <MoreHorizMenu
        disabled={!menus.length}
        items={menus}
        onSelect={handleSelect}
      />
    );
  };

  const columns: Column[] = [
    {
      label: t("user.list.label.name"),
      name: "firstName",
    },
    {
      label: t("user.list.label.firstName"),
      name: "lastName",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: t("user.list.label.role"),
      name: "role.name",
      renderer: (row: User) => {
        return row.role.name;
      },
    },
    {
      label: t("user.list.label.entity"),
      name: "",
      renderer: (row: User) => {
        return row?.entity?.name;
      },
    },
    {
      label: t("user.list.label.status"),
      name: "status",
      renderer: (row: User) => {
        const handleChange = (value: string) => {
          handleUpdateUserStatus(value, row.id);
        };
        return (
          <ChipSelect
            menuItems={userStatuses}
            onChange={handleChange}
            value={row.status}
            width={230}
            disabled={true}
          />
        );
      },
    },
    {
      label: t("user.list.label.dateAdded"),
      name: "createdAt",
      renderer: (row: User) => {
        return moment(row.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      label: t("commons.actions"),
      name: "",
      renderer: actions,
    },
  ];

  const handleCloseForm = () => {
    setUser(undefined);
    setShowForm(false);
    usersQuery.refetch();
  };

  const handleSearch = (term: string) => {
    const query = encodeParam({
      ...params,
      offset: 0,
      term,
    });
    navigate(`${pathname}?${query}`);
  };

  const handleDeleteUser = async () => {
    if (!user) {
      throw "User not defined";
    }
    deleteUser({ variables: { input: { id: user.id } } });
  };

  return (
    <Box>
      <Table
        columns={columns}
        data={users}
        title={t("user.list.title")}
        pagination={pagination}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} lg={4}>
              <Button
                variant="contained"
                onClick={() => setShowForm(true)}
                fullWidth
              >
                {t("user.list.add").toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        }
        mobileTableRow={<UserMobileRow actions={actions} user={user} />}
        model="user"
        infiniteScrollHeight="calc(100vh - 270px)"
        onSearch={handleSearch}
        term={params.term}
      />
      {showForm && (
        <UserForm
          open={showForm}
          onClose={handleCloseForm}
          user={user}
          withRole={true}
        />
      )}
      <Modal
        open={showFileViewer}
        onClose={() => setShowFileViewer(false)}
        title={t("user.files")}
        maxWidth="md"
        actions={
          <Button
            variant="contained"
            onClick={handleSubmitFiles}
            disabled={updatingFile.loading}
          >
            {t("commons.completed")}
          </Button>
        }
      >
        <FileViewer
          files={files || []}
          onStatusChange={handleUpdateFileStatus}
          loading={updatingFile.loading}
        />
      </Modal>
      <ConfirmDeleteModal
        onDelete={handleDeleteUser}
        open={showConfirmDeleteDialog}
        setOpen={setShowConfirmDeleteDialog}
        loading={deletingUser.loading}
        deleted={deletingUser.data?.deleteOneUser.id}
      >
        {gettingOrders.data?.orders.totalCount ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "75%",
              }}
            >
              <CheckCircle color="success" sx={{ my: 1 }} fontSize="large" />
              <Typography textAlign="center">
                <Typography fontWeight="bold">{`${user?.firstName} ${user?.lastName}`}</Typography>{" "}
                a encore des commandes non livrées
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "75%",
              }}
            >
              <CheckCircle color="success" sx={{ my: 1 }} fontSize="large" />
              <Typography textAlign="center">
                <Typography fontWeight="bold">{`${user?.firstName} ${user?.lastName}`}</Typography>{" "}
                n'a pas de commande en cours
              </Typography>
            </Box>
          </Box>
        )}
      </ConfirmDeleteModal>
    </Box>
  );
};
