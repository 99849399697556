import SwitchLanguage from "@components/SwitchLanguage/SwitchLanguage";
import { AppBar, Box, Toolbar, Typography, Container, Divider } from "@mui/material";
import { getFaqs } from "@src/services";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import LogoGm from "@src/assets/img/logo-gm.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Faq: FC = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [faqs, setFaqs] = useState({});
  const [activeFaqs, setActiveFaqs] = useState([]);

  useEffect(() => {
    getFaqs().then((response) => {
      setFaqs(response);
      setActiveFaqs(response.fr);
    });
  }, []);

  useEffect(() => {
    const faq = faqs[i18n.language as keyof typeof faqs];
    if (faq) setActiveFaqs(faq);
  }, [i18n.language]);

  return (
    <Box>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar color="primary">
          <Box sx={{ flexGrow: 1 }}>
            <Box
              component="img"
              src={LogoGm}
              height={64}
              alt="Logo"
            />
          </Box>
          <SwitchLanguage />
        </Toolbar>
      </AppBar>

      {activeFaqs.map((el: { question: string; comment?: string; responses: string[] }, i) => (
        <Box sx={{ maxWidth: 600, margin: "16px auto" }} key={`faq-question-${i}`}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i + 1}-content`}
              key={`panel${i + 1}-header`}
            >
              <Typography variant="h6">{`${i + 1} - ${el.question}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {el.comment && (
                <Typography variant="body2" color="textSecondary" sx={{ fontStyle: "italic", marginLeft: 2 }}>
                  {el.comment}
                </Typography>
              )}
              <Divider sx={{ marginY: 1 }} />
              {el.responses.map((response, index) => (
                <Typography key={`faq-response-${i}-${index}`} variant="body2" sx={{ marginLeft: 2 }}>{`* ${response}`}</Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
};
