import { CreateOrderState } from "@components/CreateOrderStepper";
import {
  Order,
  CarteVitale as CarteVitaleType,
  useCarte_VitalesLazyQuery,
} from "@graphql/";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useStyles } from "./styles";
import { FileUploader } from "react-drag-drop-files";
import { useFilesMutations } from "@src/hooks";
import { useApplicationContext } from "@src/context";
import { getFileType } from "@utils/getFileType";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";
import { useTranslation } from "react-i18next";
import { FILE_NAMES } from "@utils/constants";

interface CarteVitaleProps {
  setState: Dispatch<SetStateAction<CreateOrderState | undefined>>;
  state?: CreateOrderState;
  disableNextStepBtn?: boolean;
  onPreviousStep: () => void;
  order?: Order;
  onNextStep: () => void;
}

export const CarteVitale: FC<CarteVitaleProps> = ({
  setState,
  state,
  onPreviousStep,
  disableNextStepBtn,
  order,
  onNextStep,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const { t } = useTranslation();
  const [carteVitale, setCarteVitale] = useState<
    CarteVitaleType | undefined | null
  >(order?.carteVitale);
  const [loadCarteVitales, carteVitalesQuery] = useCarte_VitalesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const carteVitale = data?.carteVitales?.nodes[0];
      if (carteVitale) {
        setState((prev) => ({
          ...prev,
          carteVitale: carteVitale as CarteVitaleType,
        }));
        setCarteVitale(carteVitale as CarteVitaleType);
      }
    },
  });
  const { uploadFiles, isUploading } = useFilesMutations();
  const handleFileUploaderChange = (file: File) => {
    setState((prev) => ({ ...prev, carteVitale: undefined }));
    setFile(file);
  };
  const { connectedUser } = useApplicationContext();
  const idCustomer = connectedUser?.id;

  const fileTypes = ["JPEG", "PNG", "PDF", "JPG"];

  useEffect(() => {
    if (!order) {
      loadCarteVitales();
    }
  }, []);

  const uploadCarteVitale = () => {
    if (!file) {
      throw "file is not defined";
    }
    uploadFiles([
      {
        file: file,
        name: FILE_NAMES.CARTE_VITALE,
        filename: file?.name || "",
        type: "ORDER",
        idUser: idCustomer,
      },
    ]).then((response) => {
      loadCarteVitales({
        variables: { filter: { file: { id: { eq: response[0].id } } } },
      });
    });
  };
  return (
    <>
      {carteVitalesQuery.loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Typography variant="h5" mb={1}>
            {t("order.list.carteVitale.title")}
          </Typography>
          {carteVitale ? (
            <Box>
              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  maxWidth: "100%",
                  mb: 1,
                }}
              >
                {getFileType(carteVitale.file.mimeType) === "pdf" && (
                  <PdfViewer
                    url={decryptFilePublicUrl(carteVitale.file.url)}
                    width={600}
                  />
                )}
                {getFileType(carteVitale.file.mimeType) === "image" && (
                  <img
                    className={classes.fileContainer}
                    src={decryptFilePublicUrl(carteVitale.file.url)}
                    alt={`carte-vitale-${carteVitale.file.id}`}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </Paper>
            </Box>
          ) : (
            <Typography mb={2}>Télécharger une carte vitale valide:</Typography>
          )}
          <FileUploader
            multiple={false}
            label="Télécharger ou déposer votre carte vitale ici"
            handleChange={handleFileUploaderChange}
            types={fileTypes}
          />
        </Box>
      )}

      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={onPreviousStep}
          className={classes.createBtn}
          sx={{ marginRight: 2 }}
        >
          Précédent
        </Button>
        {state?.carteVitale ? (
          <Button
            variant="contained"
            onClick={onNextStep}
            className={classes.createBtn}
            disabled={disableNextStepBtn}
          >
            Suivant
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={uploadCarteVitale}
            className={classes.createBtn}
            disabled={isUploading}
          >
            Télécharger carte vitale
          </Button>
        )}
      </Box>
    </>
  );
};
