import {
  Exact,
  User,
  UserInfoFragment,
  Verify_Bearer_TokenQuery,
} from "../graphql";
import { Notification } from "../lib";
import { createContext } from "react";
import { ApolloQueryResult } from "@apollo/client";
import { MessagerieContext } from "./MessagerieContext";

export type ApplicationContextOptions = {
  notify: (notification: Omit<Notification, "open">) => void;
  connectedUser: User;
  isCustomer: boolean;
  isLimitedAccount: boolean;
  connectedUserType:
    | "INDIVIDUAL"
    | "PROFESSIONAL"
    | "PHARMACY"
    | "PREPARER"
    | "SUPERADMIN"
    | "LIVREUR"
    | "SERVICE_CUSTOMER";
  refetchConnectedUser: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<Verify_Bearer_TokenQuery>>;
  messagerie: MessagerieContext;
  updateConnectedUser: (user?: UserInfoFragment) => void;
  updateToken: (token: string) => void;
  token: string | null
};

const ApplicationContext = createContext<ApplicationContextOptions>({} as any);

export default ApplicationContext;
