import { Cancel, CheckCircle, RemoveCircle } from "@mui/icons-material";
import { FILE_STATUSES } from "@utils/constants";
import { useTranslation } from "react-i18next";
import React from "react"
export const useFileStatuses = () => {
  const { t } = useTranslation();

  const fileStatuses = [
    {
      value: FILE_STATUSES.NOT_VERIFIED,
      status: FILE_STATUSES.NOT_VERIFIED,
      label: t("file.statuses.NOT_VERIFIED"),
      color: "#9e9e9e",
      icon: <RemoveCircle />
    },
    {
      value: FILE_STATUSES.VALID,
      status: FILE_STATUSES.VALID,
      label: t("file.statuses.VALID"),
      color: "#4caf50",
      icon: <CheckCircle />
    },
    {
      value: FILE_STATUSES.INVALID,
      status: FILE_STATUSES.INVALID,
      label: t("file.statuses.INVALID"),
      color: "#f44336",
      icon: <Cancel/>
    },
  ];

  const getFileByStatus = (status: string) => {
    const foundStatus = fileStatuses.find((e) => e.status === status);
    if (!foundStatus) throw "file status not found";
    return foundStatus;
  };
  return { getFileByStatus, fileStatuses };
};
