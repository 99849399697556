import {
  Card,
  Box,
  Typography,
  Paper,
  Link,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import * as yup from "yup";
import { FormWrapper } from "@components/Form/Form";
import { InputProps } from "src/types";
import { useLocation } from "react-router-dom";
import { phoneRegExp } from "@src/constants";
import { useSignupMutation } from "@graphql/";
import { useApplicationContext } from "@src/context";
import moment from "moment";
import { Modal } from "@components/Modal";
import Pharmacien from "@src/assets/img/pharmacien.jpg";
import Livreur from "@src/assets/img/livreur.jpg";
import Customer from "@src/assets/img/client.jpg";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";

export const SignUp: FC = () => {
  const { search } = useLocation();
  const { notify } = useApplicationContext();

  const query = new URLSearchParams(search);
  const entity = query.get("entity") as "customer" | "PHARMACY" | "LIVREUR";
  const imgSrc = {
    customer: Customer,
    PHARMACY: Pharmacien,
    LIVREUR: Livreur,
  };
  const classes = useStyles();
  const { t } = useTranslation();
  const [signup, { loading, data }] = useSignupMutation({
    onCompleted: () => {
      notify({ message: t("signup.success"), type: "success" });
    },
    onError: (err) => {
      notify({ message: err.message, type: "error" });
    },
  });
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false);
  const [disableAcceptBtn, setDisabeAcceptBtn] = useState<boolean>(true);

  const initialCreateInput = {
    email: "",
    firstName: "",
    lastName: "",
    phoneMobile: "",
    profession: "",
    birthDate: null,
    entity: entity === "customer" ? "individual" : entity,
  };

  const togglePrivacyPolicyModal = () => {
    setOpenPrivacyPolicy(!openPrivacyPolicy);
  };

  const togglePrivacyPolicy = (accept: boolean) => {
    setAccept(accept);
    setOpenPrivacyPolicy(!openPrivacyPolicy);
  };

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("signup.errors.invalidEmail"))
        .required(t("signup.errors.requiredEmail")),
      firstName: yup.string().required(t("signup.errors.requiredFirstName")),
      lastName: yup.string().required(t("signup.errors.requiredLastName")),
      birthDate: yup
        .date()
        .typeError(t("signup.errors.invalidBirthDate"))
        .max(
          moment().subtract(18, "y").toDate(),
          t("signup.errors.mustBeAnAdult")
        )
        .required(t("signup.errors.requiredBirthDate")),
      phoneMobile: yup
        .string()
        .matches(phoneRegExp, t("signup.errors.invalidPhoneMobile"))
        .required(t("signup.errors.phoneMobileIsRequired")),
      optionalPhone: yup
        .string()
        .matches(phoneRegExp, t("signup.errors.invalidOptionalPhone"))
        .optional(),
      accept: yup.bool().oneOf([true], t("signup.errors.invalidAccept")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "entity",
      label: t("signup.customerType"),
      type: "select",
      options: [
        {
          label: t("home.individual"),
          value: "individual",
        },
        {
          label: t("home.PROFESSIONAL"),
          value: "PROFESSIONAL",
        },
      ],
      hidden: entity !== "customer",
    },
    {
      name: "email",
      label: t("commons.email"),
      type: "text",
      placeholder: t("commons.email"),
    },
    {
      name: "firstName",
      label: t("signup.firstName"),
      type: "text",
      placeholder: t("signup.firstName"),
    },
    {
      name: "lastName",
      label: t("signup.lastName"),
      type: "text",
      placeholder: t("signup.lastName"),
    },
    {
      name: "birthDate",
      label: t("signup.birthDate"),
      type: "date",
      placeholder: t("signup.birthDate"),
    },
    {
      name: "phoneMobile",
      label: t("signup.phoneMobile"),
      type: "text",
      placeholder: t("signup.phoneMobile"),
    },
    {
      name: "optionalPhone",
      label: t("signup.optionalPhone"),
      type: "text",
      placeholder: t("signup.optionalPhone"),
    },
    {
      name: "profession",
      label: t("signup.profession"),
      type: "text",
      placeholder: t("signup.profession"),
    },
    {
      name: "accept",
      label: "",
      value: accept,
      node: (
        <FormControlLabel
          label={t("signup.privacyPolicy")}
          control={<Checkbox />}
          onChange={togglePrivacyPolicyModal}
          checked={accept}
        />
      ),
      type: "node",
    },
  ];

  const onSubmit = (state: any) => {
    signup({
      variables: {
        payload: { ...state, accept: undefined, entity: undefined },
      },
      context: {
        headers: {
          entity: state.entity.toUpperCase(),
        },
      },
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const isAtBottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight;
    setDisabeAcceptBtn(!isAtBottom);
  };

  if (data?.signup.id) {
    return (
      <Box
        className={classes.root}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Paper
          elevation={12}
          sx={{ height: { sm: 300 }, width: { sm: 500 }, padding: 3 }}
        >
          <Typography
            variant="h4"
            textAlign="justify"
            className={classes.title}
          >
            {t("signup.emailSent", { email: data?.signup.email })}
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.bgPrimary}>
        <Card className={classes.formCard}>
          <Typography color="primary" variant="h4" fontWeight="bold" mb={3}>
            {t(`signup.titles.${entity}`)}
          </Typography>
          <FormWrapper
            submitBtnLabel={t("signup.signup")}
            initialCreateInput={initialCreateInput}
            inputs={inputs}
            loading={loading}
            onSubmit={onSubmit}
            schema={schema}
            values={{
              accept,
            }}
          />
          <Link
            href={process.env.CLIENT_URL}
            textAlign="center"
            variant="subtitle2"
            sx={{ mt: 3 }}
          >
            {t("signup.backToHome")}
          </Link>
        </Card>
      </Box>
      <Box>
        <Typography className={classes.title} mb={4}>
          {t(`signup.titles.${entity}`)}
        </Typography>
        <Paper elevation={12} sx={{ height: 300 }}>
          <img src={imgSrc[entity]} height="100%" />
        </Paper>
      </Box>
      <Modal
        title={t("signup.delivdosePrivacyPolicy")}
        open={openPrivacyPolicy}
        onClose={togglePrivacyPolicyModal}
        maxWidth="md"
        actions={
          <>
            <Button
              color="error"
              variant="contained"
              onClick={() => togglePrivacyPolicy(false)}
            >
              {t("commons.refuse")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => togglePrivacyPolicy(true)}
              disabled={disableAcceptBtn}
            >
              {t("commons.accept")}
            </Button>
          </>
        }
        onContentScroll={handleScroll}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PdfViewer
            url={process.env.PRIVACY_POLICY_URL as string}
            width={600}
          />
        </Box>
      </Modal>
    </Box>
  );
};
