import { useApplicationContext } from "@src/context";
import { encodeParam } from "@utils/encodeParams";
import { useParams } from "../../hooks/useParams";
import { useTranslation } from "react-i18next";
import React from "react";
import { Icon } from "@mui/material";
import { useOrderStatuses, useOrderStatusesCounts } from "@src/hooks";
import { ORDER_STATUSES } from "@utils/constants";

export const useCustomer = () => {
  const { t } = useTranslation();
  const { connectedUserType } = useApplicationContext();
  const { params } = useParams();
  const orderStatusesCounts = useOrderStatusesCounts();
  const { orderStatuses } = useOrderStatuses();

  const isCustomer =
    connectedUserType === "INDIVIDUAL" || connectedUserType === "PROFESSIONAL";

  const customerItems = isCustomer
    ? [
        {
          name: ORDER_STATUSES.ALL_ORDERS,
          pathname: `/dashboard/my-orders?${encodeParam({
            status: ORDER_STATUSES.ALL_ORDERS,
            offset: 0,
            limit: params.limit,
            from: params.from,
            to: params.to,
          })}`,
          label: t(`sideNav.orders.ALL_ORDERS`),
          count: 0,
          icon: undefined,
          subItems: orderStatuses.map((orderStatus) => {
            return {
              name: orderStatus.status,
              pathname: `/dashboard/my-orders?${encodeParam({
                status: orderStatus.status,
                offset: 0,
                limit: params.limit,
                from: params.from,
                to: params.to,
                collapsed: ORDER_STATUSES.ALL_ORDERS,
              })}`,
              label: t(`sideNav.orders.${orderStatus.status}`),
              count: orderStatusesCounts.find(
                (el: any) => el.status === orderStatus.status
              )?.count,
              icon: <Icon component={orderStatus.icon} />,
            };
          }),
        },
      ]
    : [];
  return { customerItems };
};
