import { useSupport_Tickets_CountsQuery } from "@graphql/";
import {
  AccessTime,
  AccountBalance,
  AccountCircle,
  ExpandLess,
  ExpandMore,
  Info,
  Logout,
  PedalBike,
  Place,
  Settings
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Collapse,
  CssBaseline,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useApplicationContext } from "@src/context";
import { useUsersMutations } from "@src/hooks";
import { encodeParam } from "@utils/encodeParams";
import React, { ChangeEvent, FC, MouseEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "../../hooks/useParams";
import { useStyles } from "./styles";
import { useCustomer } from "./useCustomer";
import { useLivreur } from "./useLivreur";
import { usePharmacy } from "./usePharmacy";
import { useSuperadmin } from "./useSuperadmin";
import { useServiceCustomer } from "./useServiceCustomer";

interface ListItemType {
  name?: string;
  pathname?: string;
  label: string;
  count?: number;
  icon?: ReactNode;
  subItems?: {
    name?: string;
    pathname?: string;
    label: string;
    count?: number;
    icon?: ReactNode;
    onClick?: () => void;
  }[];
  onClick?: () => void;
}

interface SideNavProps {
  onDrawerToggle: () => void;
  openMobile: boolean;
  openWeb: boolean;
  onUserFormToggle: () => void;
  onLogout: () => void;
  onFeedbackFormToggle:  React.Dispatch<React.SetStateAction<boolean>>;
}

const SideNav: FC<SideNavProps> = ({
  onDrawerToggle,
  openMobile,
  openWeb,
  onUserFormToggle,
  onLogout,
  onFeedbackFormToggle
}) => {
  const { t } = useTranslation();
  
  const { params } = useParams();
  const { pathname } = useLocation();
  const { updateUser } = useUsersMutations();
  const navigate = useNavigate();
  const { connectedUser, connectedUserType, isLimitedAccount } =
    useApplicationContext();
  const classes = useStyles();
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const supportTicketsCountsQuery = useSupport_Tickets_CountsQuery({
    fetchPolicy: "network-only",
    skip: connectedUser?.role.code !== "SERVICE_CUSTOMER",
  });

  const supportTicketsCounts =
    supportTicketsCountsQuery.data?.supportTicketsCounts || {};

  const { superadminItems } = useSuperadmin();
  const { customerItems } = useCustomer(onFeedbackFormToggle);
  const { pharmacyItems } = usePharmacy();
  const { livreurItems } = useLivreur();

  const { serviceCustomerItems } = useServiceCustomer(supportTicketsCounts);

  const mobileItems = !connectedUser?.entity
    ? [
        {
          icon: <Logout />,
          label: t("sideNav.logOut"),
          onClick: onLogout,
        },
      ]
    : connectedUser?.entity?.code === "PHARMACY" &&
      connectedUser?.role?.code === "USER"
    ? [
        {
          icon: <AccountCircle />,
          label: t("sideNav.myAccount"),
          onClick: onUserFormToggle,
        },
        {
          icon: <Logout />,
          label: t("sideNav.logOut"),
          onClick: onLogout,
        },
      ]
    : [
        {
          icon: <AccountCircle />,
          label: t("sideNav.myAccount"),
          onClick: onUserFormToggle,
        },
        {
          icon: <Logout />,
          label: t("sideNav.logOut"),
          onClick: onLogout,
        },
      ];

  const customerSettings = ["INDIVIDUAL", "PROFESSIONAL"].includes(
    connectedUserType
  )
    ? [
        {
          icon: <Place />,
          label: t("settings.address"),
          pathname: "/dashboard/settings?status=ADDRESS&collapsed=SETTINGS",
          name: "ADDRESS",
          count: 0,
        },
      ]
    : [];

  const pharmacySettings =
    connectedUserType === "PHARMACY"
      ? [
          {
            icon: <Place />,
            label: t("settings.address"),
            pathname: "/dashboard/settings?status=ADDRESS&collapsed=SETTINGS",
            name: "ADDRESS",
            count: 0,
          },
          {
            icon: <AccountBalance />,
            label: t("settings.bankAccount"),
            pathname:
              "/dashboard/settings?status=BANK_ACCOUNT&collapsed=SETTINGS",
            name: "BANK_ACCOUNT",
            count: 0,
          },
          {
            icon: <AccessTime />,
            label: t("settings.openingTime"),
            pathname:
              "/dashboard/settings?status=OPENING_TIME&collapsed=SETTINGS",
            name: "OPENING_TIME",
            count: 0,
          },
          {
            icon: <Info />,
            label: t("settings.legalInformation"),
            pathname:
              "/dashboard/settings?status=INFO_LEGALE&collapsed=SETTINGS",
            name: "INFO_LEGALE",
            count: 0,
          },
        ]
      : [];

  const livreurSettings =
    connectedUserType === "LIVREUR"
      ? [
          {
            icon: <Place />,
            label: t("settings.address"),
            pathname: "/dashboard/settings?status=ADDRESS&collapsed=SETTINGS",
            name: "ADDRESS",
            count: 0,
          },
          {
            icon: <AccountBalance />,
            label: t("settings.bankAccount"),
            pathname:
              "/dashboard/settings?status=BANK_ACCOUNT&collapsed=SETTINGS",
            name: "BANK_ACCOUNT",
            count: 0,
          },
          {
            icon: <PedalBike />,
            label: t("settings.vehicle"),
            pathname: "/dashboard/settings?status=VEHICLE&collapsed=SETTINGS",
            name: "VEHICLE",
            count: 0,
          },
        ]
      : [];

  const settingItems = ["PREPARER", "SUPERADMIN", "SERVICE_CUSTOMER"].includes(
    connectedUserType
  )
    ? []
    : [
        {
          icon: <Settings />,
          label: t("sideNav.settings"),
          pathname: "/dashboard/settings?status=ADDRESS&collapsed=SETTINGS",
          name: "SETTINGS",
          count: 0,
          subItems: [
            ...customerSettings,
            ...pharmacySettings,
            ...livreurSettings,
          ],
        },
      ];

  const listItems: ListItemType[] = [
    ...livreurItems,
    ...customerItems,
    ...pharmacyItems,
    ...superadminItems,
    ...serviceCustomerItems,
    ...settingItems,
  ];

  const handleListItemClick = (pathname?: string) => {
    if(pathname){
        navigate(pathname);    
    }
    if (onlyMobile) {
      onDrawerToggle();
    }
  };

  const handleDeliveryAvailabilityChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    updateUser({
      variables: {
        input: {
          id: connectedUser?.id,
          update: {
            deliveryAvailability: checked ? "AVAILABLE" : "UNAVAILABLE",
          },
        },
      },
    });
  };

  const handleCollapse = (
    e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
    name?: string
  ) => {
    e.stopPropagation();
    navigate(
      `${pathname}?${encodeParam({
        ...params,
        collapsed: params.collapsed === name ? "" : name,
      })}`
    );
  };

  const drawerContent = (
    <>
      <Toolbar />
      <CssBaseline />
      <Box className={classes.root}>
        <List classes={{ root: classes.collapseRoot }}>
          {connectedUser?.entity?.code === "LIVREUR" && (
            <ListItem>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      connectedUser?.deliveryAvailability === "AVAILABLE"
                    }
                    onChange={handleDeliveryAvailabilityChange}
                    sx={{
                      "& .MuiButtonBase-root": {
                        color:
                          connectedUser?.deliveryAvailability === "AVAILABLE"
                            ? "#1246B8 !important"
                            : "#DCDCDC",
                      },
                    }}
                    color="warning"
                  />
                }
                label={t("sideNav.availability")}
                classes={{ label: classes.availabilityLabel }}
                sx={{ display: { xs: "block", sm: "none" } }}
              />
            </ListItem>
          )}
          {listItems.map((listItem, index) => (
            <>
              <ListItemButton
                onClick={(e) => {
                  e.stopPropagation();
                  if(listItem.onClick){ 
                      listItem.onClick()
                  }else{
                    handleListItemClick(listItem.pathname);
                  }                 
                }}
                selected={
                  params.status === listItem.name ||
                  listItem.pathname === pathname
                }
                key={`sidenav-list-item(${listItem.pathname}`}
                classes={{
                  selected: classes.active,
                  root: classes.listItemButtonRoot,
                }}
              >
                {listItem.icon && <ListItemIcon>{listItem.icon}</ListItemIcon>}
                <ListItemText
                  classes={{ primary: classes.bold }}
                  primary={listItem.label}
                />
                <Badge
                  color="primary"
                  badgeContent={listItem.count}
                  sx={{ mr: 1 }}
                />
                {listItem.subItems ? (
                  params.collapsed === listItem.name ? (
                    <ExpandLess
                      onClick={(e) => handleCollapse(e, listItem.name)}
                    />
                  ) : (
                    <ExpandMore
                      onClick={(e) => handleCollapse(e, listItem.name)}
                    />
                  )
                ) : null}
              </ListItemButton>
              {listItem.subItems && (
                <Collapse
                  in={params.collapsed === listItem.name}
                  timeout="auto"
                  unmountOnExit
                  classes={{ root: classes.collapseRoot }}
                >
                  {listItem.subItems.map((listItem) => (
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if(listItem.onClick){ 
                            listItem.onClick()
                        }else{
                          handleListItemClick(listItem.pathname);
                        } 
                      }      
                      }
                      selected={params.status === listItem.name}
                      sx={{ pl: 4 }}
                      key={`sidenav-sublist-item(${listItem.pathname}`}
                      classes={{
                        selected: classes.active,
                        root: classes.listItemButtonRoot,
                      }}
                    >
                      <ListItemIcon>{listItem.icon}</ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.bold }}
                        primary={listItem.label}
                      />
                      <Badge color="secondary" badgeContent={listItem.count} />
                    </ListItemButton>
                  ))}
                </Collapse>
              )}
            </>
          ))}
          {mobileItems.map((listItem, index) => (
            <ListItemButton
              onClick={() => listItem.onClick()}
              sx={{ display: { lg: "none" } }}
              key={`sidenav-mobile-item(${index}`}
            >
              <ListItemIcon>{listItem.icon}</ListItemIcon>
              <ListItemText
                classes={{ primary: classes.bold }}
                primary={listItem.label}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const drawerWidth = 300;
  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={openMobile}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            paddingTop: 3,
            paddingBottom: isLimitedAccount ? 6 : 0,
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            paddingTop: 3,
          },
          display: { xs: "none", sm: "block" },
        }}
        anchor="left"
        classes={{ paper: classes.borderRight }}
        open={openWeb}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};
export { SideNav };
