import { AddressValidationResponse } from "@src/types";
import axios, { AxiosResponse } from "axios";
export const findAddress = (address: string) => {
  return axios
    .get(`${process.env.ADDRESS_API_URL}?q=${address}`)
    .then((response) => response.data.features);
};

export const validateAddress = (address: string) => {
  return axios
    .post(
      `${process.env.ADDRESS_VALIDATION_URL}?alt=json&key=${process.env.GOOGLE_MAPS_API_KEY}`,
      {
        address: {
          regionCode: "FR",
          addressLines: [address],
        },
      }
    )
    .then(({ data }: AxiosResponse<AddressValidationResponse>) => {
      return data;
    });
};

export const getFaqs = async () => {
  const fr = await axios
    .get(`${process.env.API_URL}/locales/fr/faq.json`)
    .then((response) => response.data);
  const en = await axios
    .get(`${process.env.API_URL}/locales/en/faq.json`)
    .then((response) => response.data);

  return { fr, en };
};
