import { Addchart, ManageAccounts } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { useApplicationContext } from "@src/context";
import { encodeParam } from "@utils/encodeParams";
import { useTranslation } from "react-i18next";
import { useParams } from "../../hooks/useParams";
import React from "react";
import { useOrderStatuses, useOrderStatusesCounts } from "@src/hooks";
import { ORDER_STATUSES } from "@utils/constants";
export const usePharmacy = () => {
  const { t } = useTranslation();
  const { params } = useParams();
  const orderStatusesCounts = useOrderStatusesCounts();
  const { connectedUserType } = useApplicationContext();
  const { orderStatuses } = useOrderStatuses();
  const pharmacyOrderItems = [
    {
      name: ORDER_STATUSES.ALL_ORDERS,
      pathname: `/dashboard/my-orders?${encodeParam({
        status: ORDER_STATUSES.ALL_ORDERS,
        offset: 0,
        limit: params.limit,
        from: params.from,
        tab: params.tab || ORDER_STATUSES.NEW,
        to: params.to,
      })}`,
      label: t(`sideNav.orders.ALL_ORDERS`),
      count: 0,
      icon: undefined,
      subItems: orderStatuses.map((orderStatus) => {
        return {
          name: orderStatus.status,
          pathname: `/dashboard/my-orders?${encodeParam({
            status: orderStatus.status,
            offset: 0,
            limit: params.limit,
            from: params.from,
            to: params.to,
            collapsed: ORDER_STATUSES.ALL_ORDERS,
          })}`,
          label: t(`sideNav.orders.${orderStatus.status}`),
          count: orderStatusesCounts.find(
            (el: any) => el.status === orderStatus.status
          )?.count,
          icon: <Icon component={orderStatus.icon} />,
        };
      }),
    },
  ];

  const pharmacyAdminItems = [
    {
      name: "PHARMACY_DASHBOARD",
      pathname: `/dashboard/pharmacy-dashboard?status=PHARMACY_DASHBOARD&dateRange=week`,
      label: t("sideNav.dashboard"),
      count: 0,
      icon: <Addchart />,
    },
    ...pharmacyOrderItems,
    {
      name: "Users",
      pathname: `/dashboard/my-users`,
      label: t("sideNav.users"),
      count: 0,
      icon: <ManageAccounts />,
    },
  ];

  const pharmacyItems =
    connectedUserType === "PHARMACY"
      ? pharmacyAdminItems
      : connectedUserType === "PREPARER"
      ? pharmacyOrderItems
      : [];
  return { pharmacyItems };
};
