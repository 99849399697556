import * as Types from '../../types';

import { gql } from '@apollo/client';
export type FeedbackInfoFragment = { __typename?: 'Feedback', id: string, category: string, orderRef?: string | null, score: number, comment?: string | null };

export const FeedbackInfoFragmentDoc = gql`
    fragment FeedbackInfo on Feedback {
  id
  category
  orderRef
  score
  comment
}
    `;