import {
  AddShoppingCart,
  Alarm,
  ArrowCircleDown,
  Cancel,
  DoDisturb,
  Done,
  Moped,
  Paid,
  Restore,
  Savings,
} from "@mui/icons-material";
import { useApplicationContext } from "@src/context";
import { ORDER_STATUSES, USER_TYPES } from "@utils/constants";
import { useTranslation } from "react-i18next";

export const useOrderStatuses = () => {
  const { connectedUserType } = useApplicationContext();
  const { t } = useTranslation();
  const orderStatuses = [
    {
      status: ORDER_STATUSES.NEW,
      icon: ArrowCircleDown,
      color: "#1AA7EC",
      label: t("order.statuses.NEW"),
    },
    {
      status: ORDER_STATUSES.NOT_VERIFIED,
      icon: AddShoppingCart,
      color: "#1AA7EC",
      label: t("order.statuses.NOT_VERIFIED"),
    },
    {
      status: ORDER_STATUSES.UNPAID,
      icon: Alarm,
      color: "#FC7869",
      label: t("order.statuses.UNPAID"),
    },
    {
      status: ORDER_STATUSES.PAID,
      icon: Paid,
      color: "#2ecc71",
      label: t("order.statuses.PAID"),
    },
    {
      status: ORDER_STATUSES.UNAVAILABLE,
      icon: DoDisturb,
      color: "#2C3E50",
      label: t("order.statuses.UNAVAILABLE"),
    },
    {
      status: ORDER_STATUSES.REFUNDED,
      icon: Savings,
      color: "#2ECC71",
      label: t("order.statuses.REFUNDED"),
    },
    {
      status: ORDER_STATUSES.READY,
      icon: Restore,
      color: "#4ADEDE",
      label: t("order.statuses.READY"),
    },
    {
      status: ORDER_STATUSES.DELIVERED,
      icon: Done,
      color: "#2ECC71",
      label: t("order.statuses.DELIVERED"),
    },
    {
      status: ORDER_STATUSES.ASSIGNED,
      icon: Moped,
      color: "#FFC107",
      label:
        connectedUserType === USER_TYPES.LIVREUR
          ? t("order.statuses.ASSIGNED")
          : t("order.statuses.ASSIGNED_TO_A_DELIVERY"),
    },
    {
      status: ORDER_STATUSES.CANCELED,
      icon: Cancel,
      color: "#2C3E50",
      label: t("order.statuses.CANCELED"),
    },
  ];

  const getOrderByStatus = (status: string) => {
    const foundStatus = orderStatuses.find((e) => e.status === status);
    if (!foundStatus) throw "order status not found";
    return foundStatus;
  };
  return { getOrderByStatus, orderStatuses };
};
