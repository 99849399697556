import {
  Check,
  FormatListNumbered,
  HighlightOff,
  UpdateDisabled,
} from "@mui/icons-material";
import {
  SUPPORT_TICKET_STATUSES,
  USER_TYPES,
  NOTIFICATION_IS_DONE_STATUSES,
} from "./generatedConstants";
import { COLORS } from "./colors";

export const API_URL = "";
export const DEFAULT_PAGING_LIMIT = 10;
export const userTypes = [
  USER_TYPES.INDIVIDUAL,
  USER_TYPES.PROFESSIONAL,
  USER_TYPES.PHARMACY,
  USER_TYPES.LIVREUR,
  USER_TYPES.PREPARER,
  USER_TYPES.PROFESSIONAL,
];

export const supportTicketStatuses = [
  { status: SUPPORT_TICKET_STATUSES.OPENED, icon: FormatListNumbered },
  { status: SUPPORT_TICKET_STATUSES.CLOSED, icon: UpdateDisabled },
  { status: SUPPORT_TICKET_STATUSES.RESOLVED, icon: Check },
  { status: SUPPORT_TICKET_STATUSES.CANCELED, icon: HighlightOff },
];

export * from "./generatedConstants";
