import moment from "moment";

export const useEchartsOptions = () => {
  const userUserDevicesOptions = (data: { value: string; label: string }[]) => {
    const seriesLabel = {
      show: true,
    };
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: data.map((el) => el.label),
      },
      grid: {
        left: 100,
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "value",
        name: "Utilisateurs",
      },
      yAxis: {
        type: "category",
        inverse: true,
      },
      series: data.map((el) => ({
        name: el.label,
        type: "bar",
        data: [el.value],
        label: seriesLabel,
      })),
    };
  };

  const useAgesRangesOptions = (
    data: { from: number; to: number; count: number }[]
  ) => {
    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
        // doesn't perfectly work with our tricks, disable it
        selectedMode: false,
      },
      series: [
        {
          name: "Ages",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["50%", "70%"],
          // adjust the start angle
          startAngle: 180,
          label: {
            show: true,
            formatter(param: any) {
              return `${param.name} (${param.percent}%)`;
            },
          },
          data: [
            ...data.map((el) => ({
              value: el.count,
              name: `${el.from}-${el.to} ans`,
            })),
            {
              // make an record to fill the bottom 50%
              value: data.reduce((sum, el) => sum + el.count, 0),
              itemStyle: {
                // stop the chart from rendering this piece
                color: "none",
                decal: {
                  symbol: "none",
                },
              },
              label: {
                show: false,
              },
            },
          ],
        },
      ],
    };
  };

  const useEntitiesOptions = (data: { code: string; count: number }[][]) => {
    const findData = (entity: string) => {
      return [
        data[0]?.find((el) => el.code === entity)?.count || 0,
        data[1]?.find((el) => el.code === entity)?.count || 0,
      ];
    };
    const labelOption = {
      show: true,
      position: "insideBottom",
      distance: 15,
      align: "left",
      verticalAlign: "middle",
      rotate: 90,
      formatter: "{c}  {name|{a}}",
      fontSize: 16,
      rich: {
        name: {},
      },
    };
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Client", "Livreur", "Pharmacie", "Pro"],
      },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: false },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: [moment().subtract(1, "y").year(), moment().year()],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "Client",
          type: "bar",
          barGap: 0,
          label: labelOption,
          emphasis: {
            focus: "series",
          },
          data: findData("INDIVIDUAL"),
        },
        {
          name: "Livreur",
          type: "bar",
          label: labelOption,
          emphasis: {
            focus: "series",
          },
          data: findData("LIVREUR"),
        },
        {
          name: "Pharmacie",
          type: "bar",
          label: labelOption,
          emphasis: {
            focus: "series",
          },
          data: findData("PHARMACY"),
        },
        {
          name: "Pro",
          type: "bar",
          label: labelOption,
          emphasis: {
            focus: "series",
          },
          data: findData("PROFESSIONAL"),
        },
      ],
    };
  };
  return { userUserDevicesOptions, useAgesRangesOptions, useEntitiesOptions };
};
